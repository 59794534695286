import { CALENDAR_GROUP_SHARE_LINK_INIT } from '@actions/CalendarGroupShareLink';
import { getYearSpinning, getMockEventData } from '@utils';
import moment from 'moment';
import {
  getSelectedCalendarValue,
  setSelectedCalendarValue,
} from '@utils/localStorage';
import produce from 'immer';

/**
 * 日历数据
 */
const defaultState = {
  init: false,
  isSharedLink: false,
  calendarRightMap: {}, // 日历权限映射。key 为日历编号，value 为权限值
  isAllCalendar: false, // 是否所有的子日历权限都相同
  rightCode: -1, // 所有子日历的权限
  isAdmin: false, // 是否可以管理日历分组的子日历权限
  sharedId: -1, // 分享链接的 id
  encodedSharedId: '', // 加密分享链接 id
  canSelectCalendarList: [], // 添加事件时可选择的日历
  groupId: -1, // 分享链接对应的日历分组 id
};

const CalendarGroupShareLink = produce((state, action) => {
  const { data } = action;
  switch (action.type) {
    case CALENDAR_GROUP_SHARE_LINK_INIT: {
      const { isValid, resData } = data;
      state.init = true;

      if (isValid && resData) {
        sessionStorage.setItem('isUseSessionStorageAccessToken', 'Y');
        sessionStorage.setItem('accessToken', resData.AccessToken);
        const {
          isAllCalendar,
          isAdmin,
          isPassword,
          sharedid: sharedId,
          encode,
          groupid,
        } = resData;
        const subCalendarList = resData[659962644151];

        let rightCode = undefined;
        if (isAllCalendar === 'Y') {
          rightCode = resData.rightcode;
          state.isAllCalendar = true;
          state.rightCode = rightCode;
        }

        const calendarRightMap = {};

        subCalendarList.forEach(({ calendar_id, rightcode }) => {
          calendarRightMap[calendar_id] = rightCode || rightcode;
        });

        state.isSharedLink = true;
        state.calendarRightMap = calendarRightMap;
        state.isSharedLink = true;
        state.isAdmin = isAdmin === 'Y';
        state.isPassword = isPassword === 'Y';
        state.sharedId = sharedId;
        state.encodedSharedId = encode;
        state.groupId = groupid;
      }

      break;
    }
  }
}, defaultState);

export default CalendarGroupShareLink;
