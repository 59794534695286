import React from 'react';
import Loadable from 'react-loadable';
import LoadableLoading from '@components/LoadableLoading';

export const LoginAndRegister = Loadable({
  loader: () => import('./LoginAndRegister'),
  loading() {
    return LoadableLoading;
  },
});

export const PageContainer = Loadable({
  loader: () => import('./PageContainer'),
  loading() {
    return LoadableLoading;
  },
});

export const CalendarList = Loadable({
  loader: () => import('./PageContainer/CalendarList'),
  loading() {
    return LoadableLoading;
  },
});
export const UserReserveCalendar = Loadable({
  loader: () => import('./UserReserveCalendar'),
  loading() {
    return LoadableLoading;
  },
});
export const UserReserveCalendar2 = Loadable({
  loader: () => import('./UserReserveCalendar2'),
  loading() {
    return LoadableLoading;
  },
});

export const CalendarPanel = Loadable({
  loader: () => import('./PageContainer/CalendarPanel'),
  loading() {
    return LoadableLoading;
  },
});

export const CreateCalendarType = Loadable({
  loader: () => import('./PageContainer/CreateCalendarType'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveCalendar = Loadable({
  loader: () => import('./ReserveCalendar'),
  loading() {
    return LoadableLoading;
  },
});
export const AppearanceDesign = Loadable({
  loader: () => import('./ReserveCalendar/AppearanceDesign'),
  loading() {
    return LoadableLoading;
  },
});

export const CheckBookPage = Loadable({
  loader: () => import('./ReserveCalendar/CheckBookPage'),
  loading() {
    return LoadableLoading;
  },
});

export const ChooseFields = Loadable({
  loader: () => import('./ReserveCalendar/ChooseFields'),
  loading() {
    return LoadableLoading;
  },
});

export const EditorMobile = Loadable({
  loader: () => import('./ReserveCalendar/EditorMobile'),
  loading() {
    return LoadableLoading;
  },
});

export const LeisureSchedule = Loadable({
  loader: () => import('./ReserveCalendar/LeisureSchedule'),
  loading() {
    return LoadableLoading;
  },
});

export const Card = Loadable({
  loader: () => import('./ReserveCalendar/LeisureSchedule/Card'),
  loading() {
    return LoadableLoading;
  },
});

export const PageMessage = Loadable({
  loader: () => import('./ReserveCalendar/PageMessage'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveAfter = Loadable({
  loader: () => import('./ReserveCalendar/ReserveAfter'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveBefore = Loadable({
  loader: () => import('./ReserveCalendar/ReserveBefore'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveFormPayment = Loadable({
  loader: () => import('./ReserveCalendar/ReserveFormPayment'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveInformCancel = Loadable({
  loader: () => import('./ReserveCalendar/ReserveInformCancel'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveInformChange = Loadable({
  loader: () => import('./ReserveCalendar/ReserveInformChange'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveInformEstablish = Loadable({
  loader: () => import('./ReserveCalendar/ReserveInformEstablish'),
  loading() {
    return LoadableLoading;
  },
});

export const TimeQuantum = Loadable({
  loader: () => import('./ReserveCalendar/TimeQuantum'),
  loading() {
    return LoadableLoading;
  },
});

export const ValidatePassword = Loadable({
  loader: () => import('./UserReserveCalendar/ValidatePassword'),
  loading() {
    return LoadableLoading;
  },
});

export const VisitorCalendarEvent = Loadable({
  loader: () => import('./ReserveCalendar/VisitorCalendarEvent'),
  loading() {
    return LoadableLoading;
  },
});

export const EventsView = Loadable({
  loader: () => import('./EventsView'),
  loading() {
    return LoadableLoading;
  },
});

export const EventDetails = Loadable({
  loader: () => import('./EventDetails/EventDetails'),
  loading() {
    return LoadableLoading;
  },
});

export const Home = Loadable({
  loader: () => import('./Home'),
  loading() {
    return LoadableLoading;
  },
  delay: 300,
});

export const WelcomeContainer = Loadable({
  loader: () => import('./WelcomeContainer'),
  loading() {
    return LoadableLoading;
  },
});

export const ActivityInfo = Loadable({
  loader: () => import('./ShareCalendar/ActivityInfo'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveForm = Loadable({
  loader: () => import('./ShareCalendar/ReserveForm'),
  loading() {
    return LoadableLoading;
  },
});

export const FeedBackQuestion = Loadable({
  loader: () => import('./ShareCalendar/FeedBackQuestion'),
  loading() {
    return LoadableLoading;
  },
});

export const PersonCenter = Loadable({
  loader: () => import('./PersonCenter'),
  loading() {
    return LoadableLoading;
  },
});

export const PublisherManage = Loadable({
  loader: () => import('./PersonCenter/MyShareCalendar'),
  loading() {
    return LoadableLoading;
  },
});

export const AccountInfo = Loadable({
  loader: () => import('./PersonCenter/AccountInfo'),
  loading() {
    return LoadableLoading;
  },
});

export const MyReserveCalendar = Loadable({
  loader: () => import('./PersonCenter/MyReserveCalendar'),
  loading() {
    return LoadableLoading;
  },
});

export const MyShareCalendar = Loadable({
  loader: () => import('./PersonCenter/MyActivityShare'),
  loading() {
    return LoadableLoading;
  },
});

export const IntroduceCardOfShare = Loadable({
  loader: () => import('./PersonCenter/IntroduceCard/IntroduceCardOfShare'),
  loading() {
    return LoadableLoading;
  },
});
export const IntroduceCardOfReserve = Loadable({
  loader: () => import('./PersonCenter/IntroduceCard/IntroduceCardOfReserve'),
  loading() {
    return LoadableLoading;
  },
});

export const Square = Loadable({
  loader: () => import('./Square'),
  loading() {
    return LoadableLoading;
  },
});

export const EditorPC = Loadable({
  loader: () => import('./EditorPC'),
  loading() {
    return LoadableLoading;
  },
});

export const GuidanceModeMobile = Loadable({
  loader: () => import('./ReserveCalendar/GuidanceModeMobile'),
  loading() {
    return LoadableLoading;
  },
});

export const GuidanceMode = Loadable({
  loader: () => import('./EditorPC/GuidanceMode'),
  loading() {
    return LoadableLoading;
  },
});
export const CreateShareCalendar = Loadable({
  loader: () => import('./ShareCalendar/CreateShareCalendar'),
  loading() {
    return LoadableLoading;
  },
});

export const EventDetail = Loadable({
  loader: () => import('./PageContainer/EventDetail'),
  loading() {
    return LoadableLoading;
  },
});
export const ServeType = Loadable({
  loader: () => import('./ReserveCalendar/ServeType'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveOrderDetail = Loadable({
  loader: () => import('./ReserveOrderDetail'),
  loading() {
    return LoadableLoading;
  },
});

export const MyPersonCenter = Loadable({
  loader: () => import('./MyPersonCenter'),
  loading() {
    return LoadableLoading;
  },
});

export const MyOrder = Loadable({
  loader: () => import('./MyOrder'),
  loading() {
    return LoadableLoading;
  },
});

export const ReserveServeType = Loadable({
  loader: () => import('./ServeType'),
  loading() {
    return LoadableLoading;
  },
});

export const GroupSet = Loadable({
  loader: () => import('./GroupSet'),
  loading() {
    return LoadableLoading;
  },
});

export const GroupShareSetting = Loadable({
  loader: () => import('./GroupShareSetting'),
  loading() {
    return LoadableLoading;
  },
});

export const GroupShareLinkEdit = Loadable({
  loader: () => import('./GroupShareLinkEdit'),
  loading() {
    return LoadableLoading;
  },
});

export const CheckActivity = Loadable({
  loader: () => import('./CheckActivity'),
  loading() {
    return LoadableLoading;
  },
});

export const WXLoginTest = Loadable({
  loader: () => import('./WXLoginTest'),
  loading() {
    return LoadableLoading;
  },
});

// export const NewWelcome = Loadable({
//   loader: () => import('./NewWelcome'),
//   loading() {
//     return LoadableLoading;
//   },
// });
