import React from 'react';
import SetHeader from '../../components/SetHeader';
// import { SetHeader, Guidance, Navigation } from "../loadableCommon";
import Navigation from './Navigation';
import PropTypes from 'prop-types';
import './CalendarLayout.less';
import http from '../../utils/api';
import Guidance from '../Guidance';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import moment from 'moment';
import logo from './assets/logo.png';
const NODE_ENV = process.env.NODE_ENV;
const domain = window.icalendar[NODE_ENV].domain;
const seconds = 60;
const navigations = [
  {
    name: 'schedule',
    title: '时间表',
    subNavs: [
      {
        name: 'leisureSchedule',
        title: '空闲时间表',
        component: null,
        formData: {},
      },
    ],
  },
];

// 日历布局
export default class CalendarLayout extends React.PureComponent {
  static propTypes = {
    /**
     * 导航数据
     * 默认：-
     */
    navigations: PropTypes.array.isRequired,
    // navigations = [
    //   {
    //     name: 'schedule',
    //     title: '时间表',
    //     subNavs: [
    //       {
    //         name: 'leisureSchedule',
    //         title: '空闲时间表',
    //         component: null,
    //         formData: {}
    //       }
    //     ]
    //   }
    // ]
    /**
     * 标题
     * 默认：-
     */
    title: PropTypes.string.isRequired,

    /**
     * 日历类型
     * 默认：-
     */
    calendarType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    navigations: [
      {
        name: 'schedule',
        title: '时间表',
        subNavs: [
          {
            name: 'leisureSchedule',
            title: '空闲时间表',
            component: null,
            formData: {},
          },
        ],
      },
    ],
  };

  constructor(props) {
    super(props);
    const { navigations } = props;
    this.state = {
      selectedNavName: navigations[0].name,
      selectedSubNavName: navigations[0].subNavs[0].name,
      show: true, //是否显示蒙层
      positions: [],
      currentStep: 0,
      share: false, // 是否显示分享
      calendarId: '', //链接地址
    };
  }

  getRenderContent = () => {
    const { navigations, match } = this.props;
    const { mainNav, subNav } = match.params;
    const mainItem = navigations.find((item) => item.name === mainNav);
    if (mainItem === 'high') {
      return;
    }
    const subItem = mainItem.find((item) => item.name === subNav);
    return subItem.component;
  };

  closeShare = () => {
    this.setState({
      share: false,
    });
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { calendarId, encodedSharedId, mainNav, subNav },
      },
    } = this.props;
    this.setState({
      calendarId,
    });

    !window.initUrl && (window.initUrl = window.location.href);
    let isGuidanced = JSON.parse(localStorage.getItem('isGuidanced'));

    if (!isGuidanced) {
      isGuidanced = {};
      if (await this.getGuidance()) {
        isGuidanced.isGuidanced = 'Y';
      }
    }
    // 暂时注释
    // this.setState({
    //   userInfo: JSON.parse(localStorage.getItem("userInfo")).UserInfo
    // });
    const location = this.props.history.location.pathname.split('/')[4];
    if (isGuidanced.isGuidanced === 'Y') {
      return;
    }
    if (location === 'leisureSchedule') {
      this.getPostion();
    }
    this.setState({
      show: true,
    });

    // const ua = window.navigator.userAgent.toLowerCase()
    // // 如果不在微信浏览器内，微信分享也没意义了对吧？这里判断一下
    // if (ua.indexOf('micromessenger') < 0) return false
  };

  getGuidance = async () => {
    let res;
    try {
      res = await http().getTable({
        resid: 629376724340,
      });
      if (res.data.length > 0) {
        localStorage.setItem(
          'isGuidanced',
          JSON.stringify({ isGuidanced: 'Y' })
        );
        return true;
      }
      return false;
    } catch (error) {}
  };

  handleSkip = () => {
    this.setState({
      show: false,
    });
    this.handleUserInfo();
  };
  handlePreview = () => {
    const { calendarId } = this.state;
    this.setState({
      show: false,
    });
    window.location.href = `${domain}/reserveCalendar/${calendarId}/bookPage/bookPage`;
    // this.props.history.push(
    //   `/reserveCalendar/${calendarId}/bookPage/bookPage`
    // );
  };

  handleShare = async () => {
    const { calendarId } = this.state;
    const { media } = this.props;
    if (media.mobile) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
      this.props.history.push(
        `/userReserveCalendar?calendarId=${calendarId}&share=true`
      );
      this.setState({
        share: true,
        show: true,
      });
    } else {
      this.setState({
        show: false,
      });
    }
  };

  handlePreStep = (step) => {
    const { currentStep, calendarId } = this.state;
    const { media } = this.props;
    switch (currentStep) {
      case 0:
        return;
      case 1:
        if (media.mobile) {
          document.getElementsByClassName('leisure-schedule')[0].scrollTop = 0;
        } else {
          document.getElementById('calendar-layout__main').scrollTop = 0;
        }
        this.setState({
          currentStep: currentStep - 1,
        });
        break;
      case 2:
        this.props.history.push(
          `/reserveCalendar/${calendarId}/schedule/leisureSchedule`
        );
        setTimeout(() => {
          if (media.mobile) {
            if (window.innerHeight < 600) {
              document.getElementsByClassName(
                'leisure-schedule'
              )[0].scrollTop = 600;
            } else {
              document.getElementsByClassName(
                'leisure-schedule'
              )[0].scrollTop = 380;
            }
            const TotalHeight = 840;
            const scrollTop =
              document.getElementsByClassName('leisure-schedule')[0] &&
              document.getElementsByClassName('leisure-schedule')[0].scrollTop;
            const top = TotalHeight - scrollTop;
            const { positions } = this.state;
            let _position1 = {
              top: `${top}px`,
              left: '0px',
              width: '100vw',
              height: '100px',
              dec: '然后在这里选择一个开始结束时间！',
            };
            positions.splice(1, 1, _position1);
          } else {
            document.getElementById('calendar-layout__main').scrollTop = 96;
          }
          this.setState({
            currentStep: 1,
          });
        }, 5);
        break;
      default:
        break;
    }
  };

  getElementToPageTop = (el) => {
    if (el.parentElement) {
      return this.getElementToPageTop(el.parentElement) + el.offsetTop;
    }
    return el.offsetTop;
  };

  handleNextStep = (step) => {
    const { positions, currentStep } = this.state;
    const { media, isSharedLink, encodedSharedId, history } = this.props;
    const calendarId = history.location.pathname.split('/')[2];

    const getUrl = (normalUrl) => {
      if (isSharedLink) {
        return `${normalUrl}/${encodeURIComponent(encodedSharedId)}`;
      }
      return normalUrl;
    };

    switch (currentStep) {
      case 0:
        if (media.mobile) {
          if (window.innerHeight < 600) {
            document.getElementsByClassName(
              'leisure-schedule'
            )[0].scrollTop = 600;
          } else {
            document.getElementsByClassName(
              'leisure-schedule'
            )[0].scrollTop = 420;
          }
          const TotalHeight = 840;
          const scrollTop =
            document.getElementsByClassName('leisure-schedule')[0] &&
            document.getElementsByClassName('leisure-schedule')[0].scrollTop;
          const top = TotalHeight - scrollTop;
          const { positions } = this.state;
          let _position1 = {
            top: `${top}px`,
            left: '0px',
            width: '100vw',
            height: '100px',
            dec: '然后在这里选择一个开始结束时间！',
          };
          positions.splice(1, 1, _position1);
        } else {
          document.getElementById('calendar-layout__main').scrollTop = 96;
        }
        return this.setState({
          currentStep: currentStep + 1,
        });
      case 1:
        document.getElementById('calendar-layout__main').scrollTop = 0;
        if (media.mobile) {
          history.push(
            getUrl(`/reserveCalendar/${calendarId}/timeQuantum/timeQuantum`)
          );
        } else {
          history.push(
            getUrl(`/reserveCalendar/${calendarId}/schedule/timeQuantum`)
          );
        }
        return this.setState({
          currentStep: currentStep + 1,
        });
      case 2:
        this.handleUserInfo();
        return this.setState({
          currentStep: currentStep + 1,
        });
      case 3:
        return this.setState({
          show: false,
        });
      default:
        break;
    }
  };

  handleUserInfo = async () => {
    const { userInfo } = this.state;
    const { isSharedLink } = this.props;
    if (isSharedLink) {
      localStorage.setItem('isGuidanced', JSON.stringify({ isGuidanced: 'Y' }));
    } else {
      let res;
      try {
        res = await http().addRecords({
          resid: 629376338829,
          data: [{ memberId: userInfo.EMP_USERCODE, isGuidanced: 'Y' }],
        });
        localStorage.setItem(
          'isGuidanced',
          JSON.stringify({ isGuidanced: 'Y' })
        );
      } catch (error) {}
    }
  };

  getPostion = () => {
    const { media } = this.props;
    if (media.mobile) {
      let position = {
        top: '102px',
        left: '0px',
        width: '100vw',
        height: '100px',
        dec: '然后在这里填写您的空闲时间！',
      };
      let position1;
      if (window.innerHeight < 600) {
        position1 = {
          top: '460px',
          left: '0px',
          width: '100vw',
          height: '100px',
          dec: '然后在这里选择开始结束时间！',
        };
      } else {
        position1 = {
          top: '240px',
          left: '0px',
          width: '100vw',
          height: '100px',
          dec: '然后在这里选择开始结束时间！',
        };
      }
      let position2 = {
        top: '100px',
        left: 0,
        width: '100vw',
        height: '100px',
        dec: '最后在这里设置可预订时间的长度！',
      };

      let position3 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        dec: '您已初步了解 iCal.live',
        remark: '更多功能敬请探索~ ',
      };
      const positions = [position, position1, position2, position3];
      this.setState({
        positions,
      });
    } else {
      let position = {
        top: '130px',
        left: '300px',
        width: '530px',
        height: '390px',
        dec: '然后在这里填写您的空闲时间！',
      };
      let position1 = {
        top: '460px',
        left: '300px',
        width: '530px',
        height: '102px',
        dec: '然后在这里选择开始结束时间！',
      };
      let position2 = {
        top: '150px',
        left: '300px',
        width: '530px',
        height: '52px',
        dec: '最后在这里设置可预订时间的长度！',
      };

      let position3 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        dec: '您已初步了解iCal.live',
        remark: '更多功能敬请探索~ ',
      };
      const positions = [position, position1, position2, position3];
      this.setState({
        positions,
      });
    }
  };

  offset = (element) => {
    let pos = { left: 0, top: 0 };

    let parents = element.offsetParent;

    pos.left += element.offsetLeft;
    pos.top += element.offsetTop;

    while (parents && !/html|body/i.test(parents.tagName)) {
      pos.left += parents.offsetLeft;
      pos.top += parents.offsetTop;

      parents = parents.offsetParent;
    }
    return pos;
  };
  handleOpen = (closed) => {
    this.setState({
      closed: closed ? true : false,
    });
  };

  handleClosePanel = () => {
    this.setState({
      closed: false,
    });
  };

  render() {
    const {
      selectedNavName,
      selectedSubNavName,
      positions,
      show,
      currentStep,
      closed,
      share,
      calendarId,
    } = this.state;
    const { title, navigations, match } = this.props;
    const { mainNav, subNav } = match.params;
    const mainItem = navigations.find((item) => item.name === mainNav);
    const subItem = mainItem.subNavs.find((item) => item.name === subNav);

    const renderContent = subItem.component;

    return (
      <div className='calendar-layout'>
        {positions.length > 0 ? (
          <Guidance
            positions={positions}
            handleSkip={this.handleSkip}
            handlePreStep={this.handlePreStep}
            handlePreview={this.handlePreview}
            handleShare={this.handleShare}
            closeShare={this.closeShare}
            show={show}
            share={share}
            currentStep={currentStep}
            calendarId={calendarId}
            handleNextStep={this.handleNextStep}
            {...this.props}
          ></Guidance>
        ) : null}
        <SetHeader
          tabs={mainItem.subNavs}
          selectedSubNavName={selectedSubNavName}
          {...this.props}
        />

        <div className='calendar-layout__content'>
          <div className='calendar-layout__catalogContainer'>
            <Navigation
              title={title}
              closed={closed}
              navigations={navigations}
              className='calendar-layout__catalog'
              selectedId='reservePageMessage'
              handleOpen={this.handleOpen}
              calendarId={calendarId}
              {...this.props}
            />
            {closed ? (
              <div
                className='calendar-layout__cover'
                onClick={this.handleClosePanel}
              ></div>
            ) : null}
          </div>
          <main className='calendar-layout__main' id='calendar-layout__main'>
            {renderContent}
          </main>
        </div>
      </div>
    );
  }
}
