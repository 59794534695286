export const DEV_TOOLS_SETTING = 'DEV_TOOLS_SETTING';
export const OTHER_LOGIN_METHOD_IN_WX = 'otherLoginMethodInWx';

let setting;
export const getDevToolsSetting = () => {
  try {
    setting = JSON.parse(localStorage.getItem(DEV_TOOLS_SETTING));
  } catch (err) {}
  if (!setting) {
    setting = {
      [OTHER_LOGIN_METHOD_IN_WX]: false,
    };
  }
  return setting;
};

export const setDevToolsSetting = (key, value) => {
  if (value === undefined) {
    setting = key;
    return localStorage.setItem(DEV_TOOLS_SETTING, JSON.stringify(setting));
  }
  const newSetting = getDevToolsSetting();
  newSetting[key] = value;
  setting = newSetting;
  return localStorage.setItem(DEV_TOOLS_SETTING, JSON.stringify(newSetting));
};

export const DEV_TOOLS_IS_OPEN = 'DEV_TOOLS_IS_OPEN';
let devToolsIsOpen;
export const getDevToolsIsOpen = () => {
  try {
    devToolsIsOpen = JSON.parse(localStorage.getItem(DEV_TOOLS_IS_OPEN));
  } catch (err) {}
  devToolsIsOpen = !!devToolsIsOpen;
  return devToolsIsOpen;
};

export const getOtherLoginMethodInWx = () => {
  const setting = getDevToolsSetting();
  return devToolsIsOpen && setting[OTHER_LOGIN_METHOD_IN_WX];
};
