import http from 'lz-request/lib/http';
import config from '../config';
import { dealTokenInValid } from './session';

const { API_BASE_URL } = config;

http.setDefaultBaseURL(API_BASE_URL);

// const userInfo = JSON.parse(localStorage.getItem('userInfo'));
// userInfo
//   ? // 设置默认的请求头
//     http.setDefaultHeaders({
//       'Content-Type': 'application/json',
//       accessToken: userInfo.AccessToken,
//     })
//   : http.setDefaultHeaders({
//       'Content-Type': 'application/json',
//     });

// 请求拦截
http.setRequestInterceptors(
  function (config) {
    let token;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    token = userInfo && userInfo.AccessToken;

    if (!token || typeof token !== 'string') {
      // 链接是日历分组 share link
      const isUseSessionStorageAccessToken = sessionStorage.getItem(
        'isUseSessionStorageAccessToken'
      );
      // 是日历分组分享出来的链接，则使用 sessionStorage 中的 accessToken
      if (isUseSessionStorageAccessToken === 'Y') {
        token = sessionStorage.getItem('accessToken');
      }
    }

    if (token) {
      // 请求头加上 token
      config.headers.accesstoken = token;
    }
    return config;
  },
  function (error) {
    return error;
  }
);

http.setResponseInterceptors(
  function (response) {
    const res = response.data;
    if (
      (res &&
        (res.error === 0 ||
          res.error === '0' ||
          res.Error === 0 ||
          res.Error === '0' ||
          res.OpResult === 'Y')) ||
      res === 'ok'
    ) {
      return res;
    } else {
      if (res.message.indexOf('loginerror') !== -1) {
        return dealTokenInValid();
      }
      throw new Error(res.ErrMsg || res.message || res.ErrorMsg);
    }
  },
  function (err) {
    throw new Error(err.message);
  }
);

// 默认 options
http.setDefaultOptions({
  withCredentials: true,
});

/**
 * 获取日历组
 * 参数：{ code, key }
 * 1. code:用户名或加密字符串
 * 2. key:模糊搜索
 */
http.createApi('getCalendarGroup', {
  url: '/api/ical',
});

/**
 * getICalTempEvents
 * 参数：{ date1, date2, hostid }
 * 1. date1:开始时间
 * 2. date2:结束时间
 * 2. hostid:搜索码
 */
http.createApi('getICalTempEvents', {
  url: '/api/ical/GetICalTempEvents',
});

/**
 * removeTempEventsByIds
 * 参数：{ recids }
 * 1. recids：recid 字符串，如："111,222,333"
 */
http.createApi('removeTempEventsByIds', {
  url: '/api/ical/RemoveTempEventsByIds',
});

/**
 * addTempEvents
 * 参数：{ temeventid, date, category_id, adddays, amount, alerttimes, hostid }
 * 1. temeventid：id
 * 2. date：日期，如 '2019-4-20'
 * 3. category_id：分类 id
 * 4. adddays：持续天数
 * 5. amount:值（数字）
 * 6. alerttimes:时间，如："05:00,11:00,20:30"
 * 7. hostid:搜索码，如："ivf0009"
 */

http.createApi('addTempEvents', {
  url: '/api/ical/AddTempEvents',
});

/**
 * saveByPublic
 * 参数：[record]
 * 不需要登录即可保存
 */
http.createApi('saveByPublic', {
  method: 'post',
  url: '/api/100/table/Public/Save',
});

/**
 * saveRecordAndSubTable
 * 参数：[record]
 * 保存记录 新API
 */
http.createApi('saveRecordAndSubTable', {
  method: 'post',
  url: '/api/200/table/Save',
});

/**
 * getRecordAndSubTable
 * 参数：{resid:111}
 * 获取主子表记录 新API
 */

http.createApi('getRecordAndSubTable', {
  method: 'get',
  url: '/api/200/table/Retrieve',
});

/**
 * getBookEmailTemplates
 * 获取消息通知模板
 * 参数{hostid}
 */
http.createApi('getBookEmailTemplates', {
  method: 'get',
  url: '/api/ical/GetBookEmailTemplates',
});

/**
 * saveEmailTemplate
 * 修改消息通知模板
 */
http.createApi('saveEmailTemplate', {
  method: 'post',
  url: '/api/Email/SaveEmailTemplate',
});

/**
 * saveEmailTemplate
 * 创建消息通知模板
 */
http.createApi('createBookEmailTemplates', {
  method: 'get',
  url: '/api/ical/CreateBookEmailTemplates',
});

/**
 * addBookUserResouce
 * 创建预订用户日历表单
 * 参数{BookDefID:xxx,sourceresid:xxx,parentresid:xxx}
 */
http.createApi('addBookUserResouce', {
  method: 'get',
  url: '/api/ical/AddBookUserResouce',
});

/**
 * addColumnOfBookTable
 * 添加预订用户日历表单字段
 * 传入参数
 * {
	"ColType":1,
	"ColDispName":"test8",
	"ColSize":20,
	"ColIndepResID":123456789123
   }
 */
http.createApi('addColumnOfBookTable', {
  method: 'post',
  url: '/api/ical/AddColumnOfBookTable',
});

/**
 * editColumnOfBookTable
 * 修改预订用户日历表单字段
 * 传入参数
 * {
	"ColType":1,
	"ColDispName":"测试",
	"ColSize":20,
	"ColIndepResID":123456789123,
	"ColName":"C3_622571574209"
}
 */
http.createApi('editColumnOfBookTable', {
  method: 'post',
  url: '/api/ical/EditColumnOfBookTable',
});

/**
 * setColToShowDisable
 * 设置字段不显示
 * 传入参数 :{resid:1,colname:1   }
 */
http.createApi('setColToShowDisable', {
  method: 'get',
  url: '/api/ical/SetColToShowDisable',
});
/**
 * retrieveColumnsDefineByArray
 * 获取字段定义：根据资源id获取字段定义完整定义信息,data中是数组
 * 传入参数{resid:1213}
 */

http.createApi('retrieveColumnsDefineByArray', {
  method: 'get',
  url: '/api/100/table/RetrieveColumnsDefineByArray',
});

/**
 * searchPureOcurOfBook
 * 获取预订日历信息
 * 传入参数{bookdefid:1213|startdate:2019-09-23|enddate:2019-09-23 , datafield:12}
 * showblock 默认true，是否显示无规律的管制时间
 boolean  Default value is True
 */
http.createApi('searchPureOcurOfBook', {
  method: 'get',
  url: '/api/ical/SearchPureOcurOfBook',
});

/**
 * addBookEvent
 * 预订日历
 * 传入参数
 */
http.createApi('addBookEvent', {
  method: 'post',
  url: '/api/ical/AddBookEvent',
});

/**
 * 最新的
 * AddNewBookEvent
 * 预订日历
 * 传入参数
 */
http.createApi('addNewBookEvent', {
  method: 'post',
  url: '/api/ical/AddNewBookEvent',
});

/**
 * 获取预订改期有效时间块
 * ValidTimeSectionOfChangeBookEvent
 * 传入参数
 */
http.createApi('validTimeSectionOfChangeBookEvent', {
  method: 'post',
  url: '/api/ical/ValidTimeSectionOfChangeBookEvent',
});

/**
 * addBookEventByPublic
 * 免登录预订日历
 * 传入参数
 */
http.createApi('addBookEventByPublic', {
  method: 'post',
  url: '/api/ical/AddBookEventByPublic',
});

/**
 * 最新的
 * addNewBookEventByPublic
 * 免登录预订日历
 * 传入参数
 */
http.createApi('addNewBookEventByPublic', {
  method: 'post',
  url: '/api/ical/AddNewBookEventByPublic',
});

/**
 * retrieveColumnsDefine
 * 获取表单字段
 * 传入参数 resid
 */
http.createApi('retrieveColumnsDefine', {
  method: 'get',
  url: '/api/100/table/RetrieveColumnsDefine',
});

/**
 * retrieveFormDefine
 * 获取表单字段
 * 传入参数 resid
 */
http.createApi('retrieveFormDefine', {
  method: 'get',
  url: '/api/100/table/RetrieveFormDefine',
});

/**
 * IsUserExist
 * 判断该用户是否存在
 * {userid:1}
 *
 */
http.createApi('isUserExist', {
  method: 'get',
  url: '/api/Account/IsUserExist',
});

/**
 * register
 * 注册
 * 传入参数 {userid,nickname,Handphone,validcode,validresid}
 */
http.createApi('register', {
  method: 'get',
  url: '/api/Account/Register',
});

/**
 * 注册
 * post方式
 */

http.createApi('registerPost', {
  method: 'post',
  url: '/api/Account/RegisterPost',
});

/**
 * login
 * 登录
 * 传入参数 {Code,Password}
 */
http.createApi('login', {
  method: 'post',
  url: '/api/Account/Login',
});

/**
 * 微信登录
 *
 */

http.createApi('WxAuTh', {
  method: 'get',
  url: '/WxAuTh/OAuth',
});

/**
 * 微信登录获取用户信息
 *
 */

http.createApi('getWxUserInfo', {
  method: 'get',
  url: '/api/wx/GetWxUserInfo',
});

/**
 *  重置密码
 */
http.createApi('resetPassword', {
  method: 'post',
  url: 'api/user/ResetPassword',
});

/**
 *  忘记邮箱密码
 */
http.createApi('forgetPassByEmail', {
  method: 'get',
  url: 'api/Account/ForgetPassByEmail',
});

/**
 * 更改密码
 */
http.createApi('resetPassword', {
  method: 'post',
  url: '/api/user/ResetPassword',
});

/**
 * 通过手机号、openid、unionid 来获取验证码
 * 参数：{ telephone, unionid, openid }
 * 1. telephone：手机号
 * 2. unionid：unionid
 * 3. openid：openid
 * 4. vendor:'aliyun'
 */
http.createApi('getVerCode', {
  method: 'get',
  // baseURL: 'https://finisar.realsun.me/',
  url: 'api/SMS/SMS_SendValidCode',
});

/**
 * verifyPassOfBook
 * 注册
 * 传入参数 {bookdefid,pass}
 */
http.createApi('verifyPassOfBook', {
  method: 'get',
  url: '/api/ical/VerifyPassOfBook',
});

/**
 * sendValidCode
 * 发送邮箱验证码
 * 传入参数 {email}
 */
http.createApi('sendValidCode', {
  method: 'get',
  url: '/api/Email/SendValidCode',
});

/**
 * getSysFunctionList
 * 获取系统字段列表
 * 传入参数 无
 */
http.createApi('getSysFunctionList', {
  method: 'get',
  url: '/api/ical/GetSysFunctionList',
});

/**
 * ConfirmBookEvent
 * 审核预订
 * 传入参数 {bookdefid:11,recids:[1,2]}
 */
http.createApi('confirmBookEvent', {
  method: 'get',
  url: '/api/ical/ConfirmBookEvent',
});

/**
 * rejectBookEvent
 * 拒绝预订
 * 传入参数 {bookdefid:11,recids:'1,2,3'，reasons:xxx}
 */
http.createApi('rejectBookEvent', {
  method: 'get',
  url: '/api/ical/RejectBookEvent',
});

/**
 * SetFieldsFrml
 * 设置计算公式
 * 传入参数
 * {
	"resid":624630849190,
	"colnname":"BOOKNAME",
	"fmlexp":"[USERNAME_BYID]{[bookUserId]}",
	"fmldesc":"[USERNAME_BYID]{[bookUserId]}",
	"fmltitle":"xx"
}
 */
http.createApi('setFieldsFrml', {
  method: 'post',
  url: '/api/PWFields/SetFieldsFrml',
});

/**
 * setFieldOptions
 * 设置计算公式
 * 传入参数
 * {resid:xxx,colname:xxx,options:xxx
}
 */
http.createApi('setFieldOptions', {
  method: 'get',
  url: '/api/PWFields/SetFieldOptions',
});

/**
 * setFieldsFrmls
 * 批量设置计算公式
 * 传入参数
 * [{
	"resid":624630849190,
	"colnname":"BOOKNAME",
	"fmlexp":"[USERNAME_BYID]{[bookUserId]}",
	"fmldesc":"[USERNAME_BYID]{[bookUserId]}",
	"fmltitle":"xx"
}]
 */
http.createApi('setFieldsFrmls', {
  method: 'post',
  url: '/api/PWFields/SetFieldsFrmls',
});

/**
 * DelFormula
 * 删除计算公式
 * 传入参数
 * {resid：xxx,colname:xxxx}
 */
http.createApi('delFormula', {
  method: 'post',
  url: '/api/PWFields/DelFormula',
});

/**
 * getFormula
 * 获取资源所有字段的计算公式
 * 传入参数
 * {resid：xxx}
 */
http.createApi('getFormula', {
  method: 'get',
  url: '/api/PWFields/GetFormula',
});
/**
 * getEmailTemplateList
 * 获取邮件模板
 * 传入参数 resid：xxx
 */
http.createApi('getEmailTemplateList', {
  method: 'get',
  url: '/api/Email/GetEmailTemplateList',
});

/**
 * SaveEmailTemplate
 * 保存邮件模板
 * 传入参数 resid：xxx
 */
http.createApi('saveEmailTemplate', {
  method: 'post',
  url: '/api/Email/SaveEmailTemplate',
});

/**
 * changeBookEvent
 * 预订改期
 * 传入参数 
 * {
"bookdefid":624630849190,
"recid":625251854445,
"bookEvent":{
 
	"occur_event_category":624630849190,
	"occur_begin":"2019-10-28 08:00",
	"occur_end":"2019-10-28 08:45",
	"startdate":"2019-10-22 00:00",
	"endtime":"2019-10-28 12:08"
}}
 */
http.createApi('changeBookEvent', {
  method: 'post',
  url: '/api/ical/ChangeBookEvent',
});

/**
 * 改期多个时间段
 */
http.createApi('changeBookEvents', {
  method: 'post',
  url: '/api/ical/ChangeBookEvents',
});

/**
 * 不需要授权可访问表
 * getTableByPublic
 */

http.createApi('getTableByPublic', {
  method: 'get',
  url: '/api/100/table/Public/Retrieve',
});

/**
 * 创建用户动态表单
 * addUserResouce
 */

http.createApi('addUserResouce', {
  method: 'get',
  url: '/api/ical/AddUserResouce',
});

/**
 * 获取共享日历事件
 * RetrieveCalendarOccurence
 * start=2020-3-3 00:00&end=2020-4-3 23:59&calendarid=636144416548
 */

http.createApi('retrieveCalendarOccurence', {
  method: 'get',
  url: '/api/ical/RetrieveCalendarOccurence',
});

/**
 * 获取日历种子
 * getIcs
 * http://kingofdinner.realsun.me:5201/feed/get?id=636144416548
 */

http.createApi('getIcs', {
  method: 'get',
  url: '/feed/get',
});

/**
 * 根据日历种子的url获取指定开始和结束日期的日历事件
 * getCalendarByIcsUrl
 */

http.createApi('getCalendarByIcsUrl', {
  method: 'get',
  url: '/api/ical/GetCalendarByIcsUrl',
});

/**
 * 查询带服务类型的资源日历
 * getCalendarByIcsUrl
 */

// http://kingofdinner.realsun.me:5201/api/ical/SearchPureOcurOfBooks?bookGroupId=635819091988
// &startdate=2020-4-1 00:00&enddate=2020-4-7 23:59

http.createApi('searchPureOcurOfBooks', {
  method: 'get',
  url: '/api/ical/SearchPureOcurOfBooks',
});

/**
 * 匿名报名
 * anonymitySave
 */

http.createApi('anonymitySave', {
  method: 'post',
  url: '/api/200/table/Public/Save',
});

/**
 * 注册成功后创建用户的“我的日历”
 * addUserDefaultCalendar
 */

http.createApi('addUserDefaultCalendar', {
  method: 'get',
  url: '/api/ical/AddUserDefaultCalendar',
});

/**
 * 获取多资源
http://kingofdinner.realsun.me:7201/api/iCalThread/StartSearchPureOcurOfBooks?bookGroupId=639615530400&startdate=2020-5-8 00:00&enddate=2020-5-14 23:59

 */

http.createApi('StartSearchPureOcurOfBooks', {
  method: 'get',
  url: '/api/iCalThread/StartSearchPureOcurOfBooks',
});

http.createApi('StartSearchPureOcurOfGroup', {
  method: 'get',
  url: '/api/iCalThread/StartSearchPureOcurOfGroup',
});

/**
 * 获取多资源
http://kingofdinner.realsun.me:7201/api/iCalThread/RetrieveSearchPureOcurOfBooks?taskid=642270823579&removetask=true

 */

http.createApi('RetrieveSearchPureOcurOfBooks', {
  method: 'get',
  url: '/api/iCalThread/RetrieveSearchPureOcurOfBooks',
});

/**
 * 获取多资源
http://kingofdinner.realsun.me:7201/api/iCalThread/RetrieveSearchPureOcurOfGruop?taskgroupid=642285457861&removetask=true

 */

http.createApi('RetrieveSearchPureOcurOfGruop', {
  method: 'get',
  url: '/api/iCalThread/RetrieveSearchPureOcurOfGruop',
});

/**
 * 预订日历修改后调用，缓存
http://kingofdinner.realsun.me:7201/api/iCalThread/ClearCache
 */

http.createApi('ClearCache', {
  method: 'get',
  url: '/api/iCalThread/ClearCache',
});

/**
 * 微信支付
 */

http.createApi('pay', {
  method: 'get',
  url: '/kingofweixin/pay',
});

/**
 * 微信支付
 */

http.createApi('pay0', {
  method: 'get',
  url: '/kingofweixin/RealsunPay/pay',
});

http.createApi('pay1', {
  method: 'get',
  url: '/kingofweixin/RealsunPay/pay1',
});

/**
 * 根据日历分组编号获取所有分享链接
 */
http.createApi('getSharedLinkByGroupId', {
  method: 'get',
  url: '/api/CalendarShared/GetSharedLinkByGroupId',
});

/**
 * 根据加密的日历分组编号获取所有分享链接
 */
http.createApi('getSharedLinkByGroupEncodeId', {
  method: 'get',
  url: '/api/CalendarShared/GetSharedLinkByGroupEncodeId',
});

/**
 * 修改日历分组分享链接
 */
http.createApi('editSharedLink', {
  method: 'post',
  url: '/api/CalendarShared/editSharedLink',
});

/**
 * 创建日历分组分享链接
 */
http.createApi('addSharedLinkByEncode', {
  method: 'get',
  url: '/api/CalendarShared/AddSharedLinkByEncode',
});

/**
 * 获取日历分组某个分享链接的信息
 */
http.createApi('getSharedLinkByEncode', {
  method: 'get',
  url: '/api/CalendarShared/GetSharedLinkByEncode',
});

/**
 * 修改日历分组分享链接信息
 */
http.createApi('editSharedLink', {
  method: 'post',
  url: '/api/CalendarShared/EditSharedLink',
});

/**
 * 删除日历分组分享链接
 */
http.createApi('deleteSharedLinkByEncode', {
  method: 'get',
  url: '/api/CalendarShared/DeleteSharedLinkByEncode',
});

/**
 * 通过日历分组分享的加密id，获取首页的日历和事件
 */
http.createApi('retrieveCalendarOccurenceBySharedLink', {
  method: 'get',
  url: '/api/ical/RetrieveCalendarOccurenceBySharedLink',
});

/**
 * 获取日历分组分享的链接信息
 */
http.createApi('getSharedBookGroupByEncode', {
  method: 'get',
  url: '/api/CalendarShared/GetSharedBookGroupByEncode',
});

/**
 * 重置日历分组分享链接
 */
http.createApi('resetSharedLinkByEncode', {
  method: 'get',
  url: '/api/CalendarShared/ResetSharedLinkByEncode',
});

/**
 * 用户取消预定日历
 */
http.createApi('userRejectBookEvent', {
  method: 'get',
  url: '/api/ical/UserRejectBookEvent',
});

http.createApi('startWebSocketService', {
  method: 'get',
  url: '/api/LafitSocket/StartWebSocketService',
});

http.createApi('retrieveBookTableRecords', {
  method: 'get',
  url: '/api/ical/RetrieveBookTableRecords',
});

// http.createApi("GetSignature", {
//   method: "get",
//   url: "/kingofweixin/RSAuth//GetSignature",
// });

// https://kingofdinnerwx.realsun.me/kingofweixin/RSAuth/

export default http;
