import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { Global, Home, Calendar, CalendarGroupShareLink } from './reducers';

// 合并 reducer
const reducers = combineReducers({
  Global,
  Home,
  Calendar,
  CalendarGroupShareLink,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// 创建 store
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, logger))
);

export default store;
