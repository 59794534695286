import { env } from './env';

const _config = {
  // 开发环境
  dev: {
    // 访问页面的 origin
    ORIGIN: 'http://localhost:3300',
    // 日历广场的 origin
    CALENDAR_SQUARE_ORIGIN: 'http://localhost:3400',
    // api 基地址
    API_BASE_URL: 'https://ical.realsun.me/',
    // 本地订阅日历基地址
    FEED_BASE_URL: 'webcal://kingofdinner.realsun.me:7201/',
  },
  // 测试环境
  test: {
    ORIGIN: 'http://test.ical.live:3000',
    CALENDAR_SQUARE_ORIGIN: 'http://test.ical.live:3000',
    // API_BASE_URL: 'https://ical.realsun.me/',
    API_BASE_URL: 'http://test.ical.web.api.realsun.me/',
    FEED_BASE_URL: 'webcal://kingofdinner.realsun.me:7201/',
  },
  // 生产环境
  prod: {
    ORIGIN: 'https://ical.live',
    CALENDAR_SQUARE_ORIGIN: 'https://ical.live',
    API_BASE_URL: 'https://ical.realsun.me/',
    FEED_BASE_URL: 'webcal://feed.ical.live/',
  },
};

const getConfig = () => {
  return _config[env];
};

const config = getConfig();

export default config;
