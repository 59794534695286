import http from '@utils/api';
import { message } from 'antd';

// 获取日历组
export const GET_CALENDAR_GROUP = 'GET_CALENDAR_GROUP';

// 左侧导航栏显示与隐藏切换
export const NAVIGATION_VISIBLE_SWITCH = 'NAVIGATION_VISIBLE_SWITCH';

export const CALENDAR_NOCOLLECT_CHANGE = 'CALENDAR_NOCOLLECT_CHANGE';

//日历分组表
export const calendarGroupId = '635361425012';

//我的日历表ID
export const myCalendarId = '623676968232';

//我的事件明细表ID
export const myCalendarEventsId = '623677239073';

//我的共享日历
export const myShareCalendarId = '631798050954';

// 分享链接添加事件时的 loading 状态
export const SET_SHARE_LINK_ADD_EVENT_LOADING =
  'SET_SHARE_LINK_ADD_EVENT_LOADING';

// 在分享链接首页添加事件
export const SHARE_LINK_ADD_EVENT = 'SHARE_LINK_ADD_EVENT';

/**
 * 分享链接首页添加事件
 */
export const addEvent = (calendarId, sharedId, encodedSharedId, callback) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SHARE_LINK_ADD_EVENT_LOADING,
      data: {
        loading: true,
      },
    });

    const activityID = 631731604025;
    const data = [
      {
        resid: activityID,
        maindata: {
          isActivity: 'Y',
          calendarId,
          sharedid: sharedId,
          _id: 1,
          _state: 'added',
        },
      },
    ];
    let res;
    try {
      res = await http().saveRecordAndSubTable({
        data,
      });
    } catch (err) {
      dispatch({
        type: SET_SHARE_LINK_ADD_EVENT_LOADING,
        data: {
          loading: false,
        },
      });
      return message.error(err.message);
    }
    message.success('添加成功，正在跳转...');
    dispatch({
      type: SET_SHARE_LINK_ADD_EVENT_LOADING,
      data: {
        loading: false,
      },
    });

    callback && callback(res);
  };
};
