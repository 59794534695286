import React from 'react';
import { Spin } from 'antd';
import './LoadableLoading.less';

const LoadableLoading = (props) => {
  const { error, pastDelay } = props;
  if (error) {
    return (
      <div>
        请求资源出错! <button onClick={props.retry}>重试</button>
      </div>
    );
  }

  if (pastDelay) {
    return <Spin spinning></Spin>;
  }
  return <Spin spinning></Spin>;
};

export default LoadableLoading;
