import React, { useEffect, useState } from 'react';
import { Modal, Form, Switch, Button, message } from 'antd';
import { useMove } from 'react-aria';
import { useLocalStorage, useUpdate } from 'react-use';
import http from '../../utils/api';
import qs from 'qs';
import {
  getDevToolsSetting,
  setDevToolsSetting,
  OTHER_LOGIN_METHOD_IN_WX,
  DEV_TOOLS_IS_OPEN,
  getDevToolsIsOpen,
} from './utils';

import './DevTools.less';

const DevTools = () => {
  const [devToolsIsOpen, setDevToolsIsOpen] = useState(getDevToolsIsOpen());
  const [visible, setVisible] = useState(false);
  const [setting, setSetting] = useState(getDevToolsSetting());

  useEffect(() => {
    const search = window.location.search.substring(1);
    if (!search) return;
    const qsObj = qs.parse(search);
    if (qsObj.devTools === 'true') {
      setDevToolsIsOpen(true);
      localStorage.setItem(DEV_TOOLS_IS_OPEN, 'true');
    } else if (qsObj.devTools === 'false') {
      setDevToolsIsOpen(false);
      localStorage.setItem(DEV_TOOLS_IS_OPEN, 'false');
    }
  }, []);
  useEffect(() => {
    setDevToolsSetting(setting);
  }, [setting]);

  let [position, setPosition] = React.useState({
    x: 0,
    y: 0,
  });

  let { moveProps } = useMove({
    onMove(e) {
      setPosition(({ x, y }) => {
        x += e.deltaX;
        y += e.deltaY;
        return { x, y };
      });
    },
  });

  const handleLogout = async () => {
    try {
      await http().logout();
    } catch (err) {
      console.error(err);
      return message.error(err.message);
    }
    message.success('退出登录成功');
    localStorage.removeItem('userInfo');
  };

  const handleClearCache = async () => {
    try {
      await http().clearCache();
    } catch (err) {
      console.error(err);
      return message.error(err.message);
    }
    message.success('清除后端缓存成功');
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleOtherLoginMethod = (checked) => {
    setSetting({ ...setting, [OTHER_LOGIN_METHOD_IN_WX]: checked });
  };

  if (!devToolsIsOpen) return null;

  return (
    <div className='dev-tools'>
      <button
        style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
        }}
        type='setting'
        className='dev-tools__btn'
        {...moveProps}
        onClick={() => setVisible(true)}
      >
        开发者工具
      </button>
      <Modal
        title='开发者工具'
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        maskClosable
      >
        <Form>
          <Form.Item label='微信端打开其他登录方式'>
            <Switch
              checked={setting[OTHER_LOGIN_METHOD_IN_WX]}
              onChange={handleOtherLoginMethod}
            ></Switch>
          </Form.Item>
          <Form.Item label='退出登录'>
            <Button onClick={handleLogout}>退出登录</Button>
          </Form.Item>
          <Form.Item label='清除后端缓存(需要登录)'>
            <Button onClick={handleClearCache}>清除后端缓存(需要登录)</Button>
          </Form.Item>
          <Form.Item label='重新加载页面'>
            <Button onClick={handleReload}>重新加载页面</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DevTools;
