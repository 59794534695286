/**
 * 环境变量
 */
let _env;
if (process.env.REACT_APP_ENV === 'prod') {
  _env = 'prod';
} else if (process.env.REACT_APP_ENV === 'test') {
  _env = 'test';
} else {
  _env = 'dev';
}

export const env = _env;
