import React, { useEffect, useState } from 'react';
import CalendarLayout from '../../components/CalendarLayout';
import {
  AppearanceDesign,
  PageMessage,
  ReserveBefore,
  TimeQuantum,
  LeisureSchedule,
  CheckBookPage,
  EditorMobile,
  GuidanceModeMobile,
  ServeType,
} from '../loadableCustom';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { getSharedLinkByEncode } from '@actions/CalendarGroupShareLink';

import './ReserveCalendar.less';

const ReserveCalendar = React.memo((props) => {
  const [init, setInit] = useState(false);

  const navigations = [
    {
      name: 'schedule',
      title: '时间表',
      subNavs: [
        {
          name: 'leisureSchedule',
          title: '空闲时间表',
          component: <LeisureSchedule media={props.media} {...props} />,
          style: {
            minWidth: 86,
          },
        },
        {
          name: 'timeQuantum',
          title: '时间段',
          component: <TimeQuantum media={props.media} {...props} />,
          style: {
            minWidth: 62,
          },
        },
        {
          name: 'ServeType',
          title: '服务类型',
          component: <ServeType media={props.media} {...props} />,
          style: {
            minWidth: 62,
          },
        },
      ],
    },
    {
      name: 'reserveForm',
      title: '预订表单',
      subNavs: [
        {
          name: 'reserveBefore',
          title: '预订前表单',
          component: <ReserveBefore media={props.media} {...props} />,
        },
      ],
    },

    {
      name: 'pageMessage',
      title: '页面信息',
      subNavs: [
        {
          name: 'pageMessage',
          title: '页面信息',
          component: <PageMessage media={props.media} {...props} />,
        },
      ],
    },
    {
      name: 'appearanceDesign',
      title: '外观设计',
      subNavs: [
        {
          name: 'appearanceDesign',
          title: '外观设计',
          component: <AppearanceDesign media={props.media} {...props} />,
        },
      ],
    },
    {
      name: 'bookPage',
      title: '预订管理',
      subNavs: [
        {
          name: 'bookPage',
          title: '预订管理',
          component: <CheckBookPage media={props.media} {...props} />,
        },
      ],
    },
  ];

  const navigationsMobile = [
    {
      name: 'guidance',
      title: '向导模式',
      subNavs: [
        {
          name: 'guidance',
          title: '向导模式',
          component: <GuidanceModeMobile media={props.media} {...props} />,
          style: {
            minWidth: 86,
          },
        },
      ],
    },
    {
      name: 'editorMobile',
      title: '标准模式',
      subNavs: [
        {
          name: 'editorMobile',
          title: '标准模式',
          component: <EditorMobile media={props.media} {...props} />,
          style: {
            minWidth: 86,
          },
        },
      ],
    },
    {
      name: 'schedule',
      title: '时间表',
      subNavs: [
        {
          name: 'leisureSchedule',
          title: '空闲时间表',
          component: <LeisureSchedule media={props.media} {...props} />,
          style: {
            minWidth: 86,
          },
        },
      ],
    },
    {
      name: 'timeQuantum',
      title: '时间段',
      subNavs: [
        {
          name: 'timeQuantum',
          title: '时间段',
          component: <TimeQuantum media={props.media} {...props} />,
        },
      ],
    },

    {
      name: 'serveType',
      title: '服务类型',
      subNavs: [
        {
          name: 'serveType',
          title: '服务类型',
          component: <ServeType media={props.media} {...props} />,
        },
      ],
    },
    {
      name: 'reserveForm',
      title: '预订表单',
      subNavs: [
        {
          name: 'reserveBefore',
          title: '预订前表单',
          component: <ReserveBefore media={props.media} {...props} />,
        },
      ],
    },

    {
      name: 'pageMessage',
      title: '页面信息',
      subNavs: [
        {
          name: 'pageMessage',
          title: '页面信息',
          component: <PageMessage media={props.media} {...props} />,
        },
      ],
    },
    {
      name: 'appearanceDesign',
      title: '外观设计',
      subNavs: [
        {
          name: 'appearanceDesign',
          title: '外观设计',
          component: <AppearanceDesign media={props.media} {...props} />,
        },
      ],
    },
    {
      name: 'bookPage',
      title: '预订管理',
      subNavs: [
        {
          name: 'bookPage',
          title: '预订管理',
          component: <CheckBookPage media={props.media} {...props} />,
        },
      ],
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    const {
      match: {
        params: { encodedSharedId },
      },
    } = props;
    // 分享链接
    if (encodedSharedId) {
      dispatch(
        getSharedLinkByEncode(decodeURIComponent(encodedSharedId), () =>
          setInit(true)
        )
      );
    } else {
      setInit(true);
    }
    return () => {};
  }, []);

  const { media } = props;

  const { isSharedLink, encodedSharedId } = useSelector(
    (state) => state.CalendarGroupShareLink
  );

  if (!init) {
    return (
      <Spin spinning>
        <div style={{ width: '100vw', height: '100vh' }}></div>
      </Spin>
    );
  }

  return (
    <div className='reserve-calendar'>
      <CalendarLayout
        calendarType='reserveCalendar'
        navigations={media.mobile ? navigationsMobile : navigations}
        title='预订日历设置'
        isSharedLink={isSharedLink}
        encodedSharedId={encodedSharedId}
        {...props}
      />
    </div>
  );
});

export default withRouter(ReserveCalendar);
