import { message } from 'antd';

export const dealTokenInValid = (msg = '您的登录验证已过期，请重新登录！') => {
  // 判断当前页面是不是“/"，如果是的话则跳转welcome，不跳转登录
  if(window.location.pathname!='/'){
    localStorage.removeItem('userInfo');
    localStorage.setItem('historyHref', window.location.href);
    message.info(msg);
    window.location.replace('/home');
  }else{
    window.open('https://ical.live/welcome/','_self');
  }
};
