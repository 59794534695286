// 选中的日历
export const SELECTED_CALENDAR_KEY = 'selectedCalendarKey';

export const setItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

/**
 * 从 localStorage 中获取选中日历的 groupID 值数组值
 *
 * @return {undefined | array} 返回 undefined 表示全部选中；返回数组表示选中数组中的
 */
export const getSelectedCalendarValue = () => {
  const result = getItem(SELECTED_CALENDAR_KEY);
  let selectedCalendar;
  try {
    selectedCalendar = JSON.parse(result);
  } catch (err) {}

  return selectedCalendar;
};

/**
 * 设置选中日历的 id 到 localStorage
 * @param {string} id groupID 或 category_id
 * @param {boolean} checked 是否选中
 */
export const setSelectedCalendarValue = (id, checked) => {
  let value = getSelectedCalendarValue();

  if (Object.prototype.toString.call(value) === '[object Object]') {
    value = { ...value, [id]: checked };
  } else {
    value = { [id]: checked };
  }
  return setItem(SELECTED_CALENDAR_KEY, JSON.stringify(value));
};

/**
 * 日历是否被选中
 * @param {object} selectedCalendarValue 选中的 groupID 或 category_id 作为 key 的对象。如果 selectedCalendarValue 为 undefined，则默认被选中
 * @param {string} id groupID 或 category_id
 *
 * @returns {boolean} true 日历被选中 | false 日历没有被选中
 */
export const isCalendarChecked = (selectedCalendarValue, id) => {
  if (!selectedCalendarValue) {
    return true;
  }

  return !!selectedCalendarValue[id];
};
