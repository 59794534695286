import {
  CHECKED_ALL_CHANGE,
  CALENDAR_CHECKED_CHANGE,
  GROUP_CHECKED_CHANGE,
  CALENDAR_COLLECT_CHANGE,
  CALENDAR_COLLECTALL_CHANGE,
  GET_CALENDAR,
  SEARCH_EVEVNT,
  SET_LOADING,
  SET_YEAR_SPINNING,
  SET_YEAR_EVENTS,
  SEARCH_CALENDAR,
  SET_CALENDAR_RIGHT,
  CHANGE_CALENDAR_NAME,
} from '@actions/Calendar';
import { getYearSpinning, getMockEventData } from '@utils';
import moment from 'moment';
import {
  getSelectedCalendarValue,
  setSelectedCalendarValue,
} from '@utils/localStorage';
import produce from 'immer';

/**
 * 改变日历的名称
 * @param {array} list 改变日历的名称
 * @param {number} calendarId 日历 id
 * @param {string} name 新的日历名称
 */
const changeCalendarName = (list, calendarId, name) => {
  const groupLen = list.length;
  for (let i = 0; i < groupLen; i++) {
    let hasFind = false;
    const calendarGroupItem = list[i];
    if (Array.isArray(calendarGroupItem.calendars)) {
      const calendarLen = calendarGroupItem.calendars.length;
      for (let j = 0; j < calendarLen; j++) {
        const calendar = calendarGroupItem.calendars[j];
        if (Number(calendarId) === Number(calendar.category_id)) {
          calendar.category_name = name;
          hasFind = true;
          break;
        }
      }
    }
    if (hasFind) {
      break;
    }
  }
};

/**
 * 在计划视图中，向传入的 selectedEvents 中添加事件（在迭代 calendars 数组时调用）
 * @param {Array} selectedEvents - 选中的事件
 * @param {object} calendarData - 日历数据
 */
const addEvent2SelectedEventInPlanView = (selectedEvents, calendarData) => {
  // 在计划视图中，给没有事件的日历添加一条模式的事件数据
  if (calendarData.checked) {
    const calendarItem = selectedEvents.find(
      (selectedEventsItem) =>
        selectedEventsItem.formdata &&
        selectedEventsItem.formdata.category_name === calendarData.category_name
    );
    if (!calendarItem) {
      const event = getMockEventData(calendarData.category_name);
      event.calendarId = calendarData.category_id;
      selectedEvents.push(event);
    }
  }
};

// 选中的年事件
const selectedYearEvents = [];
/**
 * 通过 selectedYearEvents 获取 selectedEvents
 */
const getSelectedEventsByYearEvents = () => {
  const selectedEvents = [];
  selectedYearEvents.forEach((mountEvents) => {
    if (Array.isArray(mountEvents)) {
      mountEvents.forEach((event) => {
        if (event) {
          selectedEvents.push(event);
        }
      });
    }
  });
  return selectedEvents;
};

/**
 * 处理权限
 * @param {number} calendarRight 日历的权限编号
 * @param {object} event 事件
 * @param {number} sharedId 分享链接 id
 */
const dealRight = (calendarRight, event, sharedId) => {
  switch (calendarRight) {
    // 只读无详情
    case 1: {
      event.hasPopover = false;
      event.hasTitle = false;
      break;
    }
    // 只读有详情
    case 2: {
      event.hasPopover = true;
      event.hasTitle = true;
      event.hasEditBtn = false; // 没有编辑按钮
      event.hasViewBtn = true; // 有查看按钮
      break;
    }
    // 新增无详情
    case 3: {
      const eventSharedId =
        event.sharedid || (event.formdata && event.formdata.sharedid);
      // 不是该分享链接创建的事件，不能查看该事件的详情
      if (String(eventSharedId) !== String(sharedId)) {
        event.hasPopover = false;
        event.hasTitle = false;
      }
      break;
    }
    // 新增有详情
    case 4: {
      const eventSharedId =
        event.sharedid || (event.formdata && event.formdata.sharedid);

      // 不是该分享链接创建的事件，不能编辑该事件
      if (String(eventSharedId) !== String(sharedId)) {
        event.hasEditBtn = false; // 没有编辑按钮
        event.hasViewBtn = true; // 有查看按钮
      }
      break;
    }
    // 协作编辑
    case 5: {
      event.hasViewBtn = true;
      break;
    }
    // 管理员
    case 6: {
      event.hasViewBtn = true;
      break;
    }

    default:
      break;
  }
};

/**
 * 处理后端返回的日历分组数据，来获取处理后的 “日历分组数据” 和 “选中的事件”
 * @param {arraay} calendarGroupList 日历分组列表
 * @param {object} options 选项
 */
const getCalendarGroupListAndEvnetList = (calendarGroupList, options = {}) => {
  const {
    selectedCalendarValue,
    calendarRightMap,
    rightCode,
    isSharedLink,
    type,
    sharedId,
    sharedLinkGroupId,
  } = options;

  console.log({ calendarRightMap });
  // 选中的事件
  const selectedEvents = [];
  const isCheckedAll = true;

  // 处理每一个日历分组下的数据
  calendarGroupList.forEach((calendarGroup) => {
    // 设置日历分组是否被选中
    if (!selectedCalendarValue) {
      calendarGroup.checked = true;
      setSelectedCalendarValue(String(calendarGroup.groupID), true);
    } else {
      const checked = selectedCalendarValue[calendarGroup.groupID];
      // 防止新添加了添加了日历，selectedCalendarValue 中没有包含其 id
      if (checked === undefined) {
        calendarGroup.checked = true;
        setSelectedCalendarValue(String(calendarGroup.groupID), true);
      } else {
        calendarGroup.checked = checked;
      }
    }

    // 根据日历分组名称排序
    Array.isArray(calendarGroup.calendars) &&
      calendarGroup.calendars.sort(sortByName);

    // 当为日历分组的分享链接时
    // 添加 isAllCalendar 和 rightCode 属性
    if (isSharedLink) {
      // 是否为分享日历的日历分组
      if (String(sharedLinkGroupId) === String(calendarGroup.groupID)) {
        calendarGroup.isSharedLinkGroup = true;
        calendarGroup.isAllCalendar = true;
        calendarGroup.rightCode = rightCode;

        // 移除 rightCode 为 0 的子日历
        if (Array.isArray(calendarGroup.calendars)) {
          calendarGroup.calendars = calendarGroup.calendars.filter((item) => {
            const code = calendarRightMap[item.category_id];
            if (code === 0) {
              return false;
            }
            return true;
          });
        }
      } else {
        calendarGroup.isSharedLinkGroup = false;
      }
    }

    // 处理每一个日历下的数据
    if (Array.isArray(calendarGroup.calendars)) {
      calendarGroup.calendars.forEach((calendar) => {
        // 当为日历分组的分享链接时
        if (isSharedLink) {
          // 没有收藏按钮
          if (calendarGroup.isSharedLinkGroup) {
            calendar.hasCollect = false;
          } else {
            calendar.hasCollect = true;
          }
          // 日历权限
          calendar.rightCode = calendarRightMap[calendar.category_id];
        } else {
          calendar.hasCollect = true;
        }

        if (!selectedCalendarValue) {
          calendar.checked = true;
          setSelectedCalendarValue(String(calendar.category_id), true);
        } else {
          const checked = selectedCalendarValue[calendar.category_id];
          // 防止新添加了添加了日历，selectedCalendarValue 中没有包含其 id
          if (checked === undefined) {
            calendar.checked = true;
            setSelectedCalendarValue(String(calendar.category_id), true);
          } else {
            calendar.checked = checked;
          }
        }

        // 权限划分
        if (calendar.UserCalendarRights && calendar.UserCalendarRights[0]) {
          if (calendar.UserCalendarRights[0].isAssistant === 'Y') {
            calendar.auth = 'edit';
          } else if (calendar.UserCalendarRights[0].isAddDetailPermit === 'Y') {
            calendar.auth = 'add';
          } else if (calendar.UserCalendarRights[0].isAdmin === 'Y') {
            calendar.auth = 'admin';
          } else {
            calendar.auth = 'readOnly';
          }
        } else {
          // 如果UserCalendarRights 为空。那么该日历为当前人创建，权限为admin
          calendar.auth = 'admin';
        }
        const category_color = calendar.category_color;

        // 处理日历下的所有事件数据
        if (Array.isArray(calendar.EventOccurences)) {
          calendar.EventOccurences.forEach((event) => {
            if (typeof calendar.rightCode === 'number') {
              dealRight(calendar.rightCode, event, sharedId);
            } else {
              event.hasViewBtn = true;
            }
              
            event.auth = calendar.auth;
            event.calendarId = calendar.category_id;
            event.category_color =
              category_color ||
              (event.formdata && event.formdata.category_color);
            event && calendar.checked && selectedEvents.push(event);
          });
        }
        // 在计划视图中，给没有事件的日历添加一条模式的事件数据
        if (type === 'plan') {
          addEvent2SelectedEventInPlanView(selectedEvents, calendar);
        }
      });
    }
  });

  // 权限为 3, 4, 5, 6 的日历才能够添加事件
  let canSelectCalendarList = [];
  if (isSharedLink) {
    const calendarGroup = calendarGroupList.find(
      (calendarGroup) =>
        String(sharedLinkGroupId) === String(calendarGroup.groupID)
    );
    if (calendarGroup) {
      canSelectCalendarList = [calendarGroup].map((calendarGroup) => {
        const canAddEventRightCodeList = [3, 4, 5, 6];
        const retCalendarGroup = { ...calendarGroup };
        if (Array.isArray(retCalendarGroup.calendars)) {
          retCalendarGroup.calendars = retCalendarGroup.calendars.filter(
            (calendar) => canAddEventRightCodeList.includes(calendar.rightCode)
          );
        }
        return retCalendarGroup;
      });
    }
  }

  // 选中的事件排序
  selectedEvents.sort(sortByDate);

  return { calendarGroupList, selectedEvents, canSelectCalendarList };
};

/**
 * 获取日历数据和事件
 * @param {array} calendarData 日历数据
 *
 * @returns {object} { calendarData, selectedEvents } calendarData:处理后的日历数据；selectedEvents:选中的事件
 */
const getCalendarListAndEvents = (
  calendarData,
  { calendarRightMap, rightCode, isSharedLink }
) => {
  const selectedEvents = [];
  const selectedCalendarValue = getSelectedCalendarValue();
  calendarData.forEach((calendarDataItem) => {
    if (!calendarDataItem) {
      return;
    }

    // 添加 isAllCalendar 和 rightCode 属性
    if (isSharedLink) {
      calendarDataItem.isAllCalendar = true;
      calendarDataItem.rightCode = rightCode;
      // 移除 rightCode 为 0 的子日历
      if (Array.isArray(calendarDataItem.calendars)) {
        calendarDataItem.calendars = calendarDataItem.calendars.filter(
          (item) => {
            const code = calendarRightMap[item.category_id];
            if (code === 0) {
              return false;
            }
            return true;
          }
        );
      }
    }

    if (Array.isArray(calendarDataItem.calendars)) {
      calendarDataItem.calendars.sort(sortByName);

      if (!selectedCalendarValue) {
        calendarDataItem.checked = true;
        setSelectedCalendarValue(String(calendarDataItem.groupID), true);
      } else {
        const checked = selectedCalendarValue[calendarDataItem.groupID];
        // 防止新添加了添加了日历，selectedCalendarValue 中没有包含其 id
        if (checked === undefined) {
          calendarDataItem.checked = true;
          setSelectedCalendarValue(String(calendarDataItem.groupID), true);
        } else {
          calendarDataItem.checked = checked;
        }
      }

      calendarDataItem.calendars.forEach((calendarsItem) => {
        if (!selectedCalendarValue) {
          calendarsItem.checked = true;
          setSelectedCalendarValue(String(calendarsItem.category_id), true);
        } else {
          const checked = selectedCalendarValue[calendarsItem.category_id];
          // 防止新添加了添加了日历，selectedCalendarValue 中没有包含其 id
          if (checked === undefined) {
            calendarsItem.checked = true;
            setSelectedCalendarValue(String(calendarsItem.category_id), true);
          } else {
            calendarsItem.checked = checked;
          }
        }

        // 分享的链接通过权限来决定页面元素的显示与隐藏
        if (isSharedLink) {
          // 没有收藏按钮
          calendarsItem.hasCollect = false;
          // 日历权限
          calendarsItem.rightCode = calendarRightMap[calendarsItem.category_id];
        } else {
          calendarsItem.hasCollect = true;
        }

        // 权限划分
        if (
          Array.isArray(calendarsItem.UserCalendarRights) &&
          calendarsItem.UserCalendarRights[0]
        ) {
          const right = calendarsItem.UserCalendarRights[0];
          if (right.isAssistant === 'Y') {
            calendarsItem.auth = 'edit';
          } else if (right.isAddDetailPermit === 'Y') {
            calendarsItem.auth = 'add';
          } else if (right.isAdmin === 'Y') {
            calendarsItem.auth = 'admin';
          } else {
            calendarsItem.auth = 'readOnly';
          }
        } else {
          // 如果 UserCalendarRights 为空。那么该日历为当前人创建，权限为 admin
          calendarsItem.auth = 'admin';
        }
        const category_color = calendarsItem.category_color;
        if (Array.isArray(calendarsItem.EventOccurences)) {
          calendarsItem.EventOccurences.forEach((event) => {
            event.auth = calendarsItem.auth;
            event.calendarId = calendarsItem.category_id;
            event.category_color =
              category_color ||
              (event.formdata && event.formdata.category_color);
            event && calendarsItem.checked && selectedEvents.push(event);
          });
        }
      });
    }
  });

  // 选中的事件排序
  selectedEvents.sort(sortByDate);

  return { calendarData, selectedEvents };
};

const getCollectedCalendarAndChecked = (calendarList) => {
  let ret = [];
  if (!Array.isArray(calendarList)) {
    return ret;
  }
  const selectedEvents = [];
  calendarList.forEach((calendarGroup, index) => {
    const obj = { ...calendarGroup };
    obj.calendars = [];
    ret.push(obj);
    if (Array.isArray(calendarGroup.calendars)) {
      calendarGroup.calendars.forEach((calendarsItem) => {
        if (calendarsItem.isCollected === 'Y') {
          calendarsItem.checked = true;
          ret[index].calendars.push(calendarsItem);
        } else {
          calendarsItem.checked = false;
        }
        const category_color = calendarsItem.category_color;
        if (Array.isArray(calendarsItem.EventOccurences)) {
          calendarsItem.EventOccurences.forEach((event) => {
            event.auth = calendarsItem.auth;
            event.calendarId = calendarsItem.category_id;
            event.category_color =
              category_color ||
              (event.formdata && event.formdata.category_color);
            event && calendarsItem.checked && selectedEvents.push(event);
          });
        }
        if (calendarsItem.isCollected === 'Y') {
          return true;
        } else {
          calendarsItem.checked = false;
          return false;
        }
      });
    }
  });
  return [selectedEvents, ret];
};

const sortByName = (a, b) => {
  if (a.category_name && b.category_name) {
    return a.category_name.localeCompare(b.category_name, 'zh');
  }
};

const sortByDate = (a, b) => {
  return (
    Date.parse(moment(a.occur_begin).format('YYYY-MM-DD HH:mm')) -
    Date.parse(moment(b.occur_begin).format('YYYY-MM-DD HH:mm'))
  );
};

/**
 * 日历数据
 */
const defaultState = {
  loading: false, // 切换视图时的加载状态
  yearSpinning: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ], // 年视图每个月的加载状态
  calendarList: [], // 所有的日历列表
  renderedCalendarList: [], // 渲染的日历列表
  selectedEvents: [], // 选中的事件
  checkedAll: [], // “全部” checkbox 是否被选中
  isCollectAllChecked: false, // “已收藏” checkbox 是否被选中
  start: '', // 获取日历数据的开始日期时间
  end: '', // 获取日历数据的结束日期时间
  viewHasRequestMap: {
    singleDay: false,
    multiDay: false,
    singleWeek: false,
    multiWeek: false,
    month: false,
    year: false,
    agenda: false,
    plan: false,
  },
  type: '', // 视图类型

  /**
   * 分享链接相关的 state
   */
  canSelectCalendarList: [], // 添加事件时可选择的日历
};

const Calendar = produce((state, action) => {
  const { data } = action;
  switch (action.type) {
    case SET_LOADING: {
      state.loading = data.loading;
      break;
    }

    case SET_CALENDAR_RIGHT: {
      const {
        isAllCalendar,
        isAdmin,
        isPassword,
        sharedid: sharedId,
        encode,
      } = data;
      const subCalendarList = data[659962644151];

      let rightCode = undefined;
      if (isAllCalendar === 'Y') {
        rightCode = data.rightcode;
        state.isAllCalendar = true;
        state.rightCode = rightCode;
      }

      const calendarRightMap = {};

      subCalendarList.forEach(({ calendar_id, rightcode }) => {
        calendarRightMap[calendar_id] = rightCode || rightcode;
      });
      state.calendarRightMap = calendarRightMap;
      state.isSharedLink = true;
      state.isAdmin = isAdmin === 'Y';
      state.isPassword = isPassword === 'Y';
      state.sharedId = sharedId;
      state.encodedSharedId = encode;
      break;
    }

    case GET_CALENDAR: {
      const {
        calendar,
        type,
        calendarRightMap,
        rightCode,
        isSharedLink,
        sharedId,
        sharedLinkGroupId,
      } = data;

      const selectedCalendarValue = getSelectedCalendarValue();

      const { calendarGroupList, selectedEvents, canSelectCalendarList } =
        getCalendarGroupListAndEvnetList(calendar, {
          selectedCalendarValue,
          calendarRightMap,
          rightCode,
          isSharedLink,
          type,
          sharedId,
          sharedLinkGroupId,
        });

      let checkedAll;
      if (selectedCalendarValue) {
        checkedAll = Object.keys(selectedCalendarValue).every((key) => {
          return selectedCalendarValue[key];
        });
      } else {
        checkedAll = true;
      }

      state.renderedCalendarList = calendarGroupList;
      state.calendarList = calendarGroupList;
      state.canSelectCalendarList = canSelectCalendarList;
      state.selectedEvents = selectedEvents;
      state.hasRequest = true;
      state.checkedAll = checkedAll;
      state.loading = false;
      state.viewHasRequestMap[type] = true;
      state.type = type;
      break;
    }

    case SET_YEAR_SPINNING: {
      const { yearSpinning, month, spinning } = data;
      let _yearSpinning;
      if (Array.isArray(yearSpinning)) {
        _yearSpinning = yearSpinning;
      } else {
        _yearSpinning = getYearSpinning(state, month, spinning);
      }

      state.yearSpinning = _yearSpinning;
      break;
    }

    case GROUP_CHECKED_CHANGE: {
      const { id, checked } = data;
      const { renderedCalendarList, type } = state;
      const selectedEvents = [];
      let checkedAll = true;
      renderedCalendarList.forEach((calendarGroupItem) => {
        if (calendarGroupItem.groupID === id) {
          calendarGroupItem.checked = checked;
          setSelectedCalendarValue(String(calendarGroupItem.groupID), checked);
          if (!checked) {
            checkedAll = false;
          }

          calendarGroupItem.calendars &&
            calendarGroupItem.calendars.forEach((item) => {
              item.checked = checked;
              setSelectedCalendarValue(String(item.category_id), checked);
              if (item.checked && Array.isArray(item.EventOccurences)) {
                selectedEvents.push(...item.EventOccurences);
              }

              // 在计划视图中，给没有事件的日历添加一条模拟的事件数据
              if (type === 'plan') {
                addEvent2SelectedEventInPlanView(selectedEvents, item);
              }
            });
        } else {
          calendarGroupItem.calendars &&
            calendarGroupItem.calendars.forEach((item) => {
              if (item.checked && Array.isArray(item.EventOccurences)) {
                selectedEvents.push(...item.EventOccurences);
              }
              // 在计划视图中，给没有事件的日历添加一条模拟的事件数据
              if (type === 'plan') {
                addEvent2SelectedEventInPlanView(selectedEvents, item);
              }
            });
        }
      });
      selectedEvents.sort(sortByDate);
      state.selectedEvents = selectedEvents;
      state.checkedAll = checkedAll;
      break;
    }

    case CALENDAR_CHECKED_CHANGE: {
      const { id, checked } = data;
      const { renderedCalendarList, type } = state;
      const selectedEvents = [];
      renderedCalendarList.forEach((calendarGroupItem) => {
        if (Array.isArray(calendarGroupItem.calendars)) {
          calendarGroupItem.calendars.forEach((item) => {
            if (id === item.category_id) {
              item.checked = checked;
              setSelectedCalendarValue(String(item.category_id), checked);
            }
            if (item.checked && Array.isArray(item.EventOccurences)) {
              selectedEvents.push(...item.EventOccurences);
            }

            // 在计划视图中，给没有事件的日历添加一条模拟的事件数据
            if (type === 'plan') {
              addEvent2SelectedEventInPlanView(selectedEvents, item);
            }
          });
          calendarGroupItem.checked = calendarGroupItem.calendars.every(
            (item) => item.checked
          );
        }
      });

      const checkedAll = renderedCalendarList.every((item) => item.checked);

      selectedEvents.sort(sortByDate);

      state.selectedEvents = selectedEvents;
      state.checkedAll = checkedAll;
      break;
    }

    case CHECKED_ALL_CHANGE: {
      const { checkedAll } = data;
      const { renderedCalendarList, calendarList, type } = state;
      const selectedEvents = [];

      const calendarListCheck = (list) => {
        list.forEach((calendarGroupItem) => {
          calendarGroupItem.checked = checkedAll;
          setSelectedCalendarValue(
            String(calendarGroupItem.groupID),
            checkedAll
          );
          if (Array.isArray(calendarGroupItem.calendars)) {
            calendarGroupItem.calendars.forEach((item) => {
              item.checked = checkedAll;
              setSelectedCalendarValue(String(item.category_id), checkedAll);

              if (checkedAll && Array.isArray(item.EventOccurences)) {
                selectedEvents.push(...item.EventOccurences);
              }

              // 在计划视图中，给没有事件的日历添加一条模式的事件数据
              if (type === 'plan') {
                addEvent2SelectedEventInPlanView(selectedEvents, item);
              }
            });
          }
        });
      };

      calendarListCheck(renderedCalendarList);
      calendarListCheck(calendarList);

      selectedEvents.sort(sortByDate);
      state.selectedEvents = selectedEvents;
      state.checkedAll = checkedAll;
      break;
    }

    case CALENDAR_COLLECTALL_CHANGE: {
      const { calendarList } = state;
      const { isCollectAllChecked } = data;

      let newCalendarList,
        selectedEvents = [];
      if (isCollectAllChecked) {
        [selectedEvents, newCalendarList] =
          getCollectedCalendarAndChecked(calendarList);
      } else {
        newCalendarList = calendarList;
        const result = getCalendarListAndEvents(newCalendarList);
        selectedEvents = result.selectedEvents;
      }

      state.isCollectAllChecked = isCollectAllChecked;
      state.renderedCalendarList = newCalendarList;
      state.selectedEvents = selectedEvents;
      break;
    }

    case CALENDAR_COLLECT_CHANGE: {
      const { renderedCalendarList } = state;
      let { calendarData, isCollected } = data;
      const categoryId = calendarData.category_id;
      renderedCalendarList.some((calendarListItem) => {
        if (Array.isArray(calendarListItem.calendars)) {
          calendarListItem.calendars.some((calendarsItem) => {
            if (calendarsItem.category_id === categoryId) {
              calendarsItem.isCollected = isCollected;
              return true;
            }
          });
        }
      });
      break;
    }

    case SET_YEAR_EVENTS: {
      const {
        month,
        calendar,
        type,
        calendarRightMap,
        rightCode,
        isSharedLink,
      } = data;

      const selectedCalendarValue = getSelectedCalendarValue();
      const { calendarGroupList, selectedEvents, canSelectCalendarList } =
        getCalendarGroupListAndEvnetList(calendar, {
          selectedCalendarValue,
          calendarRightMap,
          rightCode,
          isSharedLink,
        });

      selectedYearEvents[month] = selectedEvents;
      const allSelectedEvents = getSelectedEventsByYearEvents();
      const yearSpinning = getYearSpinning(state.yearSpinning, month, false);

      state.renderedCalendarList = calendarGroupList;
      state.calendarList = calendarGroupList;
      state.selectedEvents = allSelectedEvents;
      state.yearSpinning = yearSpinning;
      state.viewHasRequestMap[type] = true;
      state.canSelectCalendarList = canSelectCalendarList;
      break;
    }

    case SEARCH_CALENDAR: {
      const { searchValue } = data;
      const { calendarList } = state;
      const renderedCalendarList = [];
      calendarList.forEach((calendarListItem, index) => {
        renderedCalendarList.push({ ...calendarListItem });
        renderedCalendarList[index].calendars = [];
        Array.isArray(calendarListItem.calendars) &&
          calendarListItem.calendars.forEach((calendarsItem) => {
            const name = calendarsItem.category_name;
            if (name.indexOf(searchValue) !== -1) {
              renderedCalendarList[index].calendars.push(calendarsItem);
            }
          });
      });
      state.renderedCalendarList = renderedCalendarList;
      break;
    }

    case SEARCH_EVEVNT: {
      const { renderedCalendarList } = state;
      const { key } = data;

      const selectedEvents = [];
      renderedCalendarList.forEach((calendarGroupItem) => {
        calendarGroupItem.calendars &&
          calendarGroupItem.calendars.forEach((item) => {
            item.EventOccurences &&
              item.EventOccurences.length > 0 &&
              item.EventOccurences.forEach((items) => {
                const name = items.event_title;
                items.calendarId = item.category_id;
                if (name.indexOf(key) !== -1) {
                  selectedEvents.push(items);
                }
              });
          });
      });
      selectedEvents.sort(sortByDate);
      state.selectedEvents = selectedEvents;
      break;
    }

    case CHANGE_CALENDAR_NAME: {
      const { calendarId, name } = data;
      const { renderedCalendarList, calendarList } = state;
      changeCalendarName(renderedCalendarList, calendarId, name);
      changeCalendarName(calendarList, calendarId, name);
      break;
    }
  }
}, defaultState);

export default Calendar;
