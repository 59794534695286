import {
  NAVIGATION_VISIBLE_SWITCH,
  SHARE_LINK_ADD_EVENT,
  SET_SHARE_LINK_ADD_EVENT_LOADING,
} from '@actions/Home';
import { getWindowSize, getItem } from '@utils/utils';
import produce from 'immer';

/**
 * 首页状态
 */
const defaultState = {
  collectOriginCalendarList: null,
  collapse: getWindowSize().width >= 1200 ? false : true, // 左侧栏是否收缩
  hasRequest: false, // 是否请求了首页的数据
  isCollectAllChecked: false, // “已收藏” 是否被选中
  isSharedLink: false, // 是否是日历分组分享链接
  addEventLoading: false, // 分享链接添加事件时的 loading
};

const Home = produce((state, action) => {
  const { type, data } = action;
  switch (type) {
    case NAVIGATION_VISIBLE_SWITCH: {
      const { collapse } = data;
      break;
    }

    case SET_SHARE_LINK_ADD_EVENT_LOADING: {
      const { loading } = data;
      state.addEventLoading = loading;
      break;
    }

    case SHARE_LINK_ADD_EVENT: {
      break;
    }

    default:
      return state;
  }
}, defaultState);

export default Home;
