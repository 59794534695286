import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import guidanceImg from './assets/guidance.png';
import guidanceImg2 from './assets/guidance2.png';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './Navigation.less';

/**
 * 导航
 */
class Navigation extends React.PureComponent {
  static propTypes = {
    /**
     * 选中的项的名称
     * 默认：-
     */
    selectedName: PropTypes.string,

    /**
     * 标题
     * 默认：-
     */
    title: PropTypes.string,

    /**
     * 列表：[{ title: '时间表', id: 'time-table' }]
     * 默认：-
     */
    catalogList: PropTypes.array,
  };

  static defaultProps = {};

  state = {
    closed: true,
    subClosed: true,
    isNew: false,
    id: '',
    tags: ['向导模式', '标准模式', '高级模式'], //侧边主菜单
  };
  componentDidMount = () => {
    this.setState({
      isNew:
        this.props.history.location.state &&
        this.props.history.location.state.isNew,
      id:
        this.props.history.location.state &&
        this.props.history.location.state.id,
    });
  };
  handleSelectItem = (item) => {
    const {
      history,
      match,
      calendarType,
      location,
      media,
      mode,
      isSharedLink,
      encodedSharedId,
    } = this.props;
    const { isNew, id } = this.state;
    const { calendarId } = match.params;
    const mainNav = item.name;
    const subNav = item.subNavs[0].name;
    if (mainNav === 'high') {
      return;
    }

    let pathname =
      mode === 'NotWithID'
        ? `/${calendarType}/${mainNav}/${subNav}`
        : `/${calendarType}/${calendarId}/${mainNav}/${subNav}`;

    if (isSharedLink) {
      pathname += `/${encodeURIComponent(encodedSharedId)}`;
    }

    if (location.pathname === pathname) {
      return;
    }
    history.push({
      pathname,
      state: { isNew, id },
    });
    this.setState({
      closed: true,
    });
    this.props.handleOpen(false);
    media && media.mobile && window.location.reload();
  };

  renderIcon = (item, mainNav) => {
    switch (item.title) {
      case '高级模式':
        return <Icon type='tool' />;
      case '标准模式':
        return <Icon type='setting'></Icon>;
      case '向导模式':
        return item.name === mainNav ? (
          <img className='navigation__guidanceIcon' src={guidanceImg2} alt='' />
        ) : (
          <img className='navigation__guidanceIcon' src={guidanceImg} alt='' />
        );
      default:
        break;
    }
  };
  renderNavigations = () => {
    const { navigations, media } = this.props;
    const { tags } = this.state;
    const { mainNav } = this.props.match.params;
    let navigationsNew = JSON.parse(JSON.stringify(navigations));
    media &&
      media.mobile &&
      navigationsNew.splice(2, 0, {
        name: 'high',
        title: '高级模式',
        subNavs: [
          {
            name: 'high',
            title: '高级模式',
            component: null,
            formData: {},
          },
        ],
      });

    return navigationsNew.map((item) => {
      return (
        <a
          key={item.name}
          href='javascript:;'
          className={classNames(
            'navigation__item',
            {
              'navigation__item--selected': item.name === mainNav,
            },
            { navigation__subItem: tags.includes(item.title) }
          )}
          onClick={() => this.handleSelectItem(item)}
        >
          {this.renderIcon(item, mainNav)}{' '}
          {tags.includes(item.title) ? null : '-'} {item.title}
        </a>
      );
    });
  };

  handleSwitch = () => {
    const { closed } = this.state;
    this.setState({ closed: !closed });
    this.props.handleOpen(closed);
  };
  handleGoToGuidance = () => {
    const { calendarId } = this.props;
    this.props.history.push(`/reserveCalendar/${calendarId}/guidance/guidance`);
  };
  handleGoToEditor = () => {
    const { calendarId } = this.props;
    this.props.history.push(
      `/reserveCalendar/${calendarId}/editorMobile/editorMobile`
    );
    window.location.reload();
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.closed !== this.props.closed) {
      this.setState({
        subClosed: !nextProps.closed,
        closed: !nextProps.closed,
      });
    }
  };

  getHomeUrl = () => {
    const { isSharedLink, encodedSharedId } = this.props;
    if (isSharedLink) {
      return `/${encodeURIComponent(encodedSharedId)}/home`;
    }
    return '/home';
  };

  renderFooter = () => {
    const {
      title,
      media: { mobile },
      isSharedLink,
      match: {
        params: { calendarId, encodedSharedId },
      },
      history,
    } = this.props;
    const { closed, subClosed } = this.state;

    if (mobile) {
      return (
        <Link
          to={this.getHomeUrl()}
          className={classNames('navigation__footer', {
            'navigation__footer--unfold': closed || subClosed,
          })}
        >
          <Icon type='left' />
          <span>返回主页</span>
        </Link>
      );
    }

    if (['共享日历设置', '预订日历设置'].includes(title)) {
      let text = '标准模式';
      if (title === '共享日历设置') {
        text = '预定日历设置';
      }
      let url = `/${calendarId}/editorPC`;
      if (isSharedLink) {
        url += `/${encodeURIComponent(encodedSharedId)}`;
      }

      return (
        <a
          onClick={() => {
            history.push(url);
          }}
          className={classNames('navigation__footer', {
            'navigation__footer--unfold': closed || subClosed,
          })}
        >
          <Icon type='left' />
          <span>{text}</span>
        </a>
      );
    }

    return (
      <Link
        to='/home'
        className={classNames('navigation__footer', {
          'navigation__footer--unfold': closed || subClosed,
        })}
      >
        <Icon type='left' />
        <span>返回主页</span>
      </Link>
    );
  };

  render() {
    const {
      title,
      className,
      calendarId,
      media: { mobile },
    } = this.props;
    const { closed, subClosed, guidanceVisible } = this.state;
    return (
      <aside
        className={classNames('navigation', className, {
          navigation__unfold: closed || subClosed,
        })}
      >
        {mobile && (
          <span
            href='javascript:;'
            className={classNames('navigation__switch', {
              'navigation__switch--unfold': closed || subClosed,
            })}
            onClick={this.handleSwitch}
          >
            {closed || subClosed ? <Icon type='menu' /> : <Icon type='close' />}
          </span>
        )}
        <h2
          className={classNames('navigation__h2', {
            'navigation__h2--unfold': closed || subClosed,
          })}
        >
          {title}
        </h2>
        <div
          className={classNames('navigation__content', {
            'navigation__content--unfold': closed || subClosed,
          })}
        >
          {this.renderNavigations()}
        </div>

        {this.renderFooter()}
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.CalendarGroupShareLink,
});

const EnhandcedNavigation = connect(mapStateToProps)(Navigation);

export default EnhandcedNavigation;
