import React from "react";
import "./Guidance.less";
import { Button, message, Icon } from "antd";
import arrowsImg from "./images/arrows.png";
import OkImg from "./images/ok.png";
import arrowsImg2 from "./images/arrows2.png";
import ClipboardJS from "clipboard";
const NODE_ENV = process.env.NODE_ENV;
const domain = window.icalendar[NODE_ENV].domain;
class Guidance extends React.Component {
  componentDidMount = () => {
    const clipboard = new ClipboardJS("#btn");
    clipboard.on("success", function(e) {
      message.success("链接复制成功，快去分享给朋友吧");
      e.clearSelection();
    });
    clipboard.on("error", function(e) {
      message.error("复制失败");
      e.clearSelection();
    });
    this._clipboard = clipboard;
  };
  componentWillUnmount = () => {
    this._clipboard.destroy();
  };
  render() {
    const {
      positions,
      dec,
      handleSkip,
      handleNextStep,
      handlePreStep,
      currentStep,
      handlePreview,
      handleShare,
      share,
      show,
      closeShare,
      media,
      calendarId,
      userShare,
      from
    } = this.props;
    return show || userShare ? (
      <div
        className="guidance"
        style={{
          width: userShare && !share ? "0" : "100vw",
          transform: "translateZ(1000px)"
        }}
      >
        {share ? (
          <div
            className="guidance_last"
            onClick={() => {
              closeShare();
            }}
          >
            <img src={arrowsImg2} className="guidance_last_arrows" />
            <div style={{ marginTop: "50px" }}>
              选择右上角 <Icon type="ellipsis"></Icon> 按钮
            </div>
            <div>
              选择分享给朋友{" "}
              <Icon type="wechat" style={{ color: "#44b549" }}></Icon>
            </div>
          </div>
        ) : null}
        {show &&
          (currentStep === 3 ? (
            <div className="guidance_last">
              <img src={OkImg} width="87px" height="114px" alt="OK" />
              <div className="guidance_last_dec">
                {positions[currentStep].dec}
              </div>
              <div className="guidance_last_remark">
                {positions[currentStep].remark}
              </div>
              <div className="guidance_last_btns">
                <Button icon="setting" onClick={handleSkip}>
                  进入设置
                </Button>
                {media.pc ? (
                  <Button
                    icon="share-alt"
                    id="btn"
                    onClick={handleShare}
                    data-clipboard-text={`${domain}/userReserveCalendar?calendarId=${calendarId}`}
                  >
                    分享链接{" "}
                  </Button>
                ) : (
                  <Button icon="share-alt" onClick={handleShare}>
                    分享链接
                  </Button>
                )}
                <Button icon="eye" onClick={handlePreview}>
                  预览日历
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <div
                className="guidance_top"
                style={{ height: positions[currentStep].top }}
              ></div>
              <div
                className="guidance_left"
                style={{
                  top: positions[currentStep].top,
                  width: positions[currentStep].left,
                  height: `calc(100vh - ${positions[currentStep].top})`
                }}
              ></div>
              <div
                className="guidance_right"
                style={{
                  top: positions[currentStep].top,
                  height: `calc(100vh - ${positions[currentStep].top})`,
                  width: `calc(100vw - ${positions[currentStep].left} - ${positions[currentStep].width})`,
                  right: 0
                }}
              ></div>
              <div
                className="guidance_bottom"
                style={{
                  height: `calc(100vh - ${positions[currentStep].top} - ${positions[currentStep].height})`,
                  width: positions[currentStep].width,
                  left: positions[currentStep].left
                }}
              ></div>
              <div className="guidance_block"></div>
              <div
                className="guidance_arrows"
                style={{
                  left:
                    media && media.pc
                      ? `calc(100vw - ${positions[currentStep].width} - 75px) `
                      : "",
                  right:
                    positions[currentStep].arrows === "right" &&
                    currentStep === 0 &&
                    media.mobile &&
                    from === "home"
                      ? "44px"
                      : positions[currentStep].arrows === "right"
                      ? "10px"
                      : "",
                  top:
                    currentStep === 0 && media.mobile && from === "home"
                      ? "-40px"
                      : `${positions[currentStep].top}`,
                  transform:
                    currentStep === 0 && media.mobile ? "rotate(90deg)" : ""
                }}
              >
                <img src={arrowsImg} />
              </div>
              <div
                className="guidance_dec"
                style={{
                  left: `calc(100vw - ${positions[currentStep].width} `,
                  top:
                    currentStep === 0 && from === "home"
                      ? "-40px"
                      : positions[currentStep].top
                }}
              >
                {positions[currentStep].dec}
              </div>
              <div
                className="guidance_btns"
                style={{
                  left: `calc(100vw - ${positions[currentStep].width} `,
                  top: `calc(${positions[currentStep].top} + 80px)`
                }}
              >
                {currentStep === 0 ? null : (
                  <Button
                    onClick={() => {
                      handlePreStep(currentStep);
                    }}
                  >
                    上一步
                  </Button>
                )}
                <Button
                  onClick={() => {
                    handleNextStep(currentStep);
                    //   this.handleNextStep(currentStep);
                  }}
                >
                  {currentStep === 3
                    ? "完成"
                    : currentStep === 0 && from === "home"
                    ? "开始创建日历"
                    : "下一步"}
                </Button>
                <Button className="guidance_btns_pass" onClick={handleSkip}>
                  跳过
                </Button>
              </div>
            </div>
          ))}
      </div>
    ) : null;
  }
}
export default Guidance;
