import axios from "axios";
import logo from "../components/Catalog/assets/logo.png";
const NODE_ENV = process.env.NODE_ENV;
const domain = window.icalendar[NODE_ENV].domain;
export const getWindowSize = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth,
    height = w.innerHeight || e.clientHeight || g.clientHeight;
  return { width, height };
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const setItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const fixIOSWXBug = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const noop = () => {};

//判断是否是微信浏览器打开
export const is_weixin = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
export const isIOSOrAndroid = () => {
  let u = navigator.userAgent,
    app = navigator.appVersion;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    return "android";
  }
  if (isIOS) {
    return "ios";
  }
};

export const getWXConfig = async (calendarId, mainData) => {
  !window.initUrl && (window.initUrl = window.location.href);
  try {
    let url;
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      url = window.location.href;
    } else if (isiOS) {
      url = window.wx_js_url || window.location.href;
    }
    let res = await axios.get("https://ical.realsun.me/api/wx/GetSignature", {
      params: {
        requesturl: window.initUrl,
      },
    });
    if (res.error === 0) {
      let data = res.data;
      await window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.AppId, // 必填，公众号的唯一标识
        timestamp: data.Timestamp, // 必填，生成签名的时间戳
        nonceStr: data.NonceStr, // 必填，生成签名的随机串
        signature: data.Signature, // 必填，签名，见附录1
        jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      window.wx.checkJsApi({
        jsApiList: ["onMenuShareAppMessage", "onMenuShareTimeline"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
      });
      window.wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        window.wx.onMenuShareAppMessage(
          {
            title: mainData.Name, // 分享标题
            desc: mainData.BookPageDesc, // 分享描述
            link: `${domain}/userReserveCalendar?calendarId=${calendarId}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: mainData.BookPageImage, // 分享图标
          },
          function (res) {
            this.closeShare();
            //这里是回调函数
          },
          function (err) {
            this.closeShare();
          }
        );
        window.wx.onMenuShareTimeline(
          {
            title: mainData.Name, // 分享标题
            desc: mainData.BookPageDesc, // 分享描述
            link: `${domain}/userReserveCalendar?calendarId=${calendarId}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: mainData.BookPageImage, // 分享图标
          },
          function (res) {
            this.closeShare();
            //这里是回调函数
          },
          function (err) {
            this.closeShare();
          }
        );
        console.log("end");
      });

      window.wx.error((res) => {
        console.log("调用微信jsapi返回的状态:", res.errMsg);
      });
    } else {
      console.error("error:  " + res.data);
    }
  } catch (error) {
    console.error(error);
  }
};
