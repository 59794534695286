/**
 * 获取浏览器宽度
 */
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )
}

/**
 * 获取浏览器高度
 */
function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  )
}

export const getMedia = () => {
  const media = {
    pc: false,
    mobile: false
  }
  const w = getWidth()
  const ua = navigator.userAgent.toLowerCase();
  const isWeixin = ua.indexOf('micromessenger') != -1;
  switch (true) {
    // pc
    case (w > 768 && !isWeixin ): {
      media.pc = true
      break
    }
    // xs
    default: {
      media.mobile = true
    }
  }
  return media
}
