import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils/utils';
import classNames from 'classnames';
import logoSvg from './assets/logo.svg';
import logoSvg2 from './assets/logo2.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './SetHeader.less';

/**
 * 设置的头部组件
 */
class SetHeader extends React.PureComponent {
  static propTypes = {
    /**
     * 标签页
     * 默认：[]
     */
    tabs: PropTypes.array,

    /**
     * 选中的 tab 的 name
     * 默认：-
     */
    selectedSubNavName: PropTypes.string,

    /**
     * 搜索时的回调:(value) => {}
     * 默认：noop
     */
    onSearch: PropTypes.func,
  };
  state = {
    isNew: false,
    id: '',
  };
  static defaultProps = {
    onChange: noop,
    searchValue: '',
  };
  componentDidMount() {
    this.setState({
      isNew:
        this.props.history.location.state &&
        this.props.history.location.state.isNew,
      id:
        this.props.history.location.state &&
        this.props.history.location.state.id,
    });
  }

  handleTabClick = (tab) => {
    const {
      history,
      match,
      calendarType,
      location,
      isSharedLink,
      encodedSharedId,
    } = this.props;
    const { isNew, id } = this.state;
    const { mainNav, calendarId } = match.params;
    const subNav = tab.name;
    let pathname;
    if (calendarId) {
      pathname = `/${calendarType}/${calendarId}/${mainNav}/${subNav}`;
    } else {
      pathname = `/${calendarType}/${mainNav}/${subNav}`;
    }

    if (isSharedLink) {
      pathname += `/${encodeURIComponent(encodedSharedId)}`;
    }

    if (location.pathname === pathname) {
      return;
    }
    history.push({
      pathname,
      state: { isNew, id },
    });
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearch = (value) => {
    this.props.onSearch(value);
  };

  renderTabs = () => {
    const { tabs, media } = this.props;

    const { match } = this.props;
    const { subNav } = match.params;

    return tabs.map((tab) => {
      const classes = classNames('set-header__tab', {
        'set-header__tab--selected': subNav === tab.name,
      });
      const style = tab.style || {};

      return (
        <div
          className={classes}
          key={tab.name}
          onClick={() => this.handleTabClick(tab)}
          style={style}
        >
          {tab.title === '向导模式' || tab.title === '标准模式' || media.pc
            ? tab.title
            : '高级模式'}
        </div>
      );
    });
  };

  render() {
    const {
      media: { pc },
      encodedSharedId,
      isSharedLink,
    } = this.props;

    let logoUrl = '/home';
    if (isSharedLink) {
      logoUrl = `/${encodeURIComponent(encodedSharedId)}${logoUrl}`;
    }

    return (
      <header className='set-header'>
        <div className='set-header__left'>
          <div className='set-header__logo'>
            <Link to={logoUrl}>
              <img src={logoSvg2} alt='logo' />
            </Link>
          </div>
          <div className='set-header__tabs'>{this.renderTabs()}</div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.CalendarGroupShareLink,
});

const EnhandcedSetHeader = connect(mapStateToProps)(SetHeader);

export default EnhandcedSetHeader;
