import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { is_weixin, isIOSOrAndroid } from './utils';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import { RedirectLogin } from './RedirectLogin';

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const setItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const loggedIn = () => {
  if (getItem('userInfo')) {
    return true;
  }
  return false;
};

export const logout = () => {
  removeItem('userInfo');
};

// 未登录回到欢迎页
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn() ? (
        <Component
          userInfo={getUserInfo()}
          is_weixin={is_weixin()}
          {...props}
          {...rest}
        />
      ) : (
        <RedirectLogin />
      )
    }
  />
);

// 微信未登录回到首页
export const PrivateWXRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn() ? (
        <Component
          userInfo={loggedIn()}
          is_weixin={is_weixin()}
          {...props}
          {...rest}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/home',
            state: {
              from: props.location,
            },
          }}
        />
      )
    }
  />
);

// 无需登录，返回对应的页面
export const PrivateOtherRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Component
        userInfo={loggedIn()}
        is_weixin={is_weixin()}
        {...props}
        {...rest}
      />
    )}
  />
);

/**
 * 获取浏览器屏幕宽度
 */
export const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};

/**
 * 获取年视图加载状态
 * @param {array} yearSpinning 年视图加载状态
 * @param {number} month 月份
 * @param {boolean} spinning 该月份的日历数据是否处于加载状态
 *
 * @returns {array} 如：[true, false, false, false, false, false, false, false, false, false, false, false]
 */
export const getYearSpinning = (yearSpinning, month, spinning) => {
  if (!Array.isArray(yearSpinning)) {
    if (typeof yearSpinning === 'boolean') {
      return new Array(12).fill(yearSpinning);
    }

    throw new Error('yearSpinning 参数类型为数组或布尔值');
  }
  if (typeof month !== 'number') {
    throw new Error('index 参数类型为数字');
  }
  if (typeof spinning !== 'boolean') {
    throw new Error('value 参数类型为布尔值');
  }
  const ret = [...yearSpinning];
  ret[month] = spinning;
  return ret;
};

/**
 * 获取一年中各个月份的开始和结束日期
 * @param {string} date 日期。如：'2020-08-04'
 *
 * @returns {array} 如：[['2020-01-01 00:00', '2020-01-31 23:59'], // 其他月份]
 */
export const getYearMonthDateRange = (date) => {
  if (!/\d{4}-\d{2}-\d{2}/.test(date)) {
    throw new Error('date 参数格式不正确');
  }

  const monthStartStr = moment(date).startOf('years').format('YYYY-MM-DD');

  const ret = [];

  for (let i = 0; i < 12; i++) {
    const startMoment = moment(monthStartStr).add(i, 'months');
    const endMoment = moment(monthStartStr).add(i, 'months');
    const start = startMoment.startOf('months');
    const end = endMoment.endOf('months');
    const item = [
      start.format('YYYY-MM-DD HH:mm'),
      end.format('YYYY-MM-DD HH:mm'),
    ];
    ret.push(item);
  }

  return ret;
};

let _scrollbarWidth;
/**
 * 获取浏览器滚动条的宽度
 */
export const getScrollbarWidth = () => {
  if (_scrollbarWidth) {
    return _scrollbarWidth;
  }
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

/**
 * 获取存储在 localStorage 中的用户信息
 */
let userInfo = null;
export const getUserInfo = (forceGet = false) => {
  if (userInfo && !forceGet) {
    return userInfo;
  }
  try {
    userInfo = JSON.parse(getItem('userInfo'));
  } catch (err) {
    console.error(err);
  }
  return userInfo;
};

/**
 * 获取媒体相关数据
 */
export const getMedia = () => {
  const media = {
    pc: false,
    mobile: false,
  };
  const w = getWidth();
  const ua = navigator.userAgent.toLowerCase();
  const isWeixin = ua.indexOf('micromessenger') != -1;
  switch (true) {
    // pc
    case w > 768 && !isWeixin: {
      media.pc = true;
      break;
    }
    // xs
    default: {
      media.mobile = true;
    }
  }
  return media;
};

/**
 * 剪贴板初始化
 * @param {string} className 元素类名
 * @param {function} onSuccess 复制成功的回调
 * @param {function} onError 复制失败的回调
 *
 * @returns {object} 剪贴板实例对象
 */
export const clipboardInit = (className, onSuccess, onError) => {
  const clipboard = new ClipboardJS(`.${className}`);
  clipboard.on('success', function (e) {
    onSuccess && onSuccess(e);
    e.clearSelection();
  });
  clipboard.on('error', function (e) {
    onError && onError(e);
    e.clearSelection();
  });
  return clipboard;
};

let occurId = -1;
/**
 * 获取模拟的事件数据
 */
export const getMockEventData = (categoryName) => {
  return {
    occur_id: occurId--,
    category_color: 'rgb(255, 160, 120)',
    event_title: 'event_title',
    event_short: 'event_short',
    event_desc: 'event_desc',
    occur_begin: moment().startOf('days').format('YYYY-MM-DDTHH:mm:ss'),
    occur_end: moment().endOf('days').format('YYYY-MM-DDTHH:mm:ss'),
    event_hostheadurl: 'http://placekitten.com/32/32',
    event_image: 'http://placekitten.com/200/150',
    event_time: '00:00',
    event_endtime: '23:59',
    event_weather: 2,
    event_attach: ['附件', 'http://www.baidu.com'],
    event_important: 0,
    category_name: categoryName,
    forbidRender: true,
    formdata: {
      isAllday: 'Y',
    },
  };
};

/**
 * 是否在微信中
 */
export const isWx = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

/**
 * 获取生成 BEM 命令法的函数
 * @param {string} prefix 类名前缀
 */
export const getGBEMClassName = (prefix) => {
  return (suffix = '') => {
    if (!suffix) {
      return prefix;
    }
    return `${prefix}__${suffix}`;
  };
};

/**
 * 加号替换：将字符串中的 '+' 替换为 'x__plus'
 * @param {string} s 字符串
 */
export const plusReplace = (s) => {
  if (!s) {
    return '';
  }
  let retString = '';
  const len = s.length;
  for (let i = 0; i < len; i++) {
    const str = s[i];
    if (str === '+') {
      retString += 'x__plus';
    } else {
      retString += str;
    }
  }
  return retString;
};

/**
 * 将 accessToken 存入 sessionStorage 中
 * @param {string} accessToken accessToken
 */
export const sessionStorageAccessToken = (accessToken) => {
  sessionStorage.setItem('isUseSessionStorageAccessToken', 'Y');
  sessionStorage.setItem('accessToken', accessToken);
};

/**
 * 将 sessionStorage 中的权限信息移除掉
 * @param {string} accessToken accessToken
 */
export const removeSessionStorageAccessToken = () => {
  sessionStorage.removeItem('isUseSessionStorageAccessToken');
  sessionStorage.removeItem('accessToken');
};

/**
 * 在新的标签页中打开链接。主要是为了兼容微信端 window.open 打不开新页面的问题
 */
export const windowOpen = (url) => {
  // 微信端
  if (is_weixin()) {
    window.location.assign(url);
  } else {
    window.open(url);
  }
};
