import React from "react";
import Loadable from "react-loadable";
const minLoading = <span>加载中...</span>;

export const CalendarLayout = Loadable({
  loader: () => import("./CalendarLayout"),
  loading() {
    return minLoading;
  },
});

export const Navigation = Loadable({
  loader: () => import("./CalendarLayout/Navigation"),
  loading() {
    return minLoading;
  },
});

export const CommonHeader = Loadable({
  loader: () => import("./CommonHeader/CommonHeader"),
  loading() {
    return minLoading;
  },
});

export const CommonEventDetails = Loadable({
  loader: () => import("./CommonEventDetails/CommonEventDetails"),
  loading() {
    return minLoading;
  },
});

export const Catalog = Loadable({
  loader: () => import("./Catalog/Catalog"),
  loading() {
    return minLoading;
  },
});

export const CheckReserveCalendar = Loadable({
  loader: () => import("./CheckReserveCalendar/CheckReserveCalendar"),
  loading() {
    return minLoading;
  },
});

export const CalendarMobileEditor = Loadable({
  loader: () => import("./CalendarMobileEditor"),
  loading() {
    return minLoading;
  },
});

export const TimeLine = Loadable({
  loader: () => import("./CheckReserveCalendar/TimeLine/TimeLine"),
  loading() {
    return minLoading;
  },
});

export const TimePiece = Loadable({
  loader: () => import("./CheckReserveCalendar/TimePiece/TimePiece"),
  loading() {
    return minLoading;
  },
});

export const ColorPicker = Loadable({
  loader: () => import("./ColorPicker"),
  loading() {
    return minLoading;
  },
});

export const Form = Loadable({
  loader: () => import("./Form"),
  loading() {
    return minLoading;
  },
});

export const FormItem = Loadable({
  loader: () => import("./Form/FormItem/FormItem"),
  loading() {
    return minLoading;
  },
});

export const Guidance = Loadable({
  loader: () => import("./Guidance"),
  loading() {
    return minLoading;
  },
});

export const ICalDrawer = Loadable({
  loader: () => import("./ICalDrawer"),
  loading() {
    return minLoading;
  },
});

export const ImagePicker = Loadable({
  loader: () => import("./ImagePicker"),
  loading() {
    return minLoading;
  },
});

export const LoadingButton = Loadable({
  loader: () => import("./LoadingButton"),
  loading() {
    return minLoading;
  },
});

export const ManageReserve = Loadable({
  loader: () => import("./ManageReserve"),
  loading() {
    return minLoading;
  },
});

export const InValidPage = Loadable({
  loader: () => import("./InValidPage"),
  loading() {
    return minLoading;
  },
});

export const SetHeader = Loadable({
  loader: () => import("./SetHeader"),
  loading() {
    return minLoading;
  },
});

export const TouchLeft = Loadable({
  loader: () => import("./TouchLeft"),
  loading() {
    return minLoading;
  },
});

export const IcalSpin = Loadable({
  loader: () => import("./IcalSpin"),
  loading() {
    return minLoading;
  },
});

export const QRCodeReact = Loadable({
  loader: () => import("./QRCodeReact"),
  loading() {
    return minLoading;
  },
});

export const SuccessModal = Loadable({
  loader: () => import("./SuccessModal"),
  loading() {
    return minLoading;
  },
});

export const TitleBar = Loadable({
  loader: () => import("./TitleBar"),
  loading() {
    return minLoading;
  },
});

export const TimeSelectorClock = Loadable({
  loader: () => import("./TimeSelectorClock"),
  loading() {
    return minLoading;
  },
});

export const GuidanceReserveCalendar = Loadable({
  loader: () => import("./GuidanceReserveCalendar"),
  loading() {
    return minLoading;
  },
});

export const CommonMobileEventDetails = Loadable({
  loader: () => import("./CommonMobileEventDetails"),
  loading() {
    return minLoading;
  },
});
export const ClockPicker = Loadable({
  loader: () => import("./ClockPicker"),
  loading() {
    return minLoading;
  },
});


export const ClockPickerFormItem = Loadable({
  loader: () => import("./ClockPicker/ClockPickerFormItem"),
  loading() {
    return minLoading;
  },
});


export const ReservePanel = Loadable({
  loader: () => import("./ReservePanel"),
  loading() {
    return minLoading;
  },
});

export const ReserveManyRecPanel = Loadable({
  loader: () => import("./ReserveManyRecPanel"),
  loading() {
    return minLoading;
  },
});


export const CommonEventDetailsForApply = Loadable({
  loader: () => import("./CommonEventDetailsForApply/CommonEventDetailsForApply"),
  loading() {
    return minLoading;
  },
});


