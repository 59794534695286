import React from 'react';
import CalendarLayout from '../../components/CalendarLayout';
import { CreateShareCalendar } from '../loadableCustom';
import './ShareCalendar.less';

const ShareCalendar = React.memo((props) => {
  const navigations = [
    {
      name: 'shareCalendar',
      title: '基本配置',
      subNavs: [
        {
          name: 'shareCalendar',
          title: '基本配置',
          component: <CreateShareCalendar media={props.media} {...props} />,
          style: {
            minWidth: 86,
          },
        },
      ],
    },
  ];

  return (
    <div className='share-calendar'>
      <CalendarLayout
        calendarType='shareCalendar'
        navigations={navigations}
        title='共享日历设置'
        {...props}
      />
    </div>
  );
});

export default ShareCalendar;
