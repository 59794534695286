import { getUserInfo, getMedia, getScrollbarWidth, isWx } from '@utils';

/**
 * Global state
 */
const defaultState = {
  userInfo: getUserInfo(),
  media: getMedia(),
  scrollBarWidth: getScrollbarWidth(),
  is_weixin: isWx(),
};

export default (state = defaultState, action) => {
  const { type, data } = action;
  switch (type) {
    default:
      return state;
  }
};
