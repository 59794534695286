import http from '@utils/api';
import moment from 'moment';
import { message } from 'antd';
import to from 'await-to-js';
import { getYearSpinning, getYearMonthDateRange } from '@utils';
import { CALENDAR_GROUP_SHARE_LINK_INIT } from './CalendarGroupShareLink';

// 获取日历组
export const GET_CALENDAR_GROUP = 'GET_CALENDAR_GROUP';

// 左侧导航栏显示与隐藏切换
export const NAVIGATION_VISIBLE_SWITCH = 'NAVIGATION_VISIBLE_SWITCH';

// 左侧全选选中改变
export const CHECKED_ALL_CHANGE = 'CHECKED_ALL_CHANGE';

// 左侧日历选中改变
export const CALENDAR_CHECKED_CHANGE = 'CALENDAR_CHECKED_CHANGE';

//左侧 选中分组
export const GROUP_CHECKED_CHANGE = 'GROUP_CHECKED_CHANGE';

//左侧日历选中查看所有收藏
export const CALENDAR_COLLECTALL_CHANGE = 'CALENDAR_COLLECTALL_CHANGE';

export const CALENDAR_COLLECT_CHANGE = 'CALENDAR_COLLECT_CHANGE';

export const CALENDAR_NOCOLLECT_CHANGE = 'CALENDAR_NOCOLLECT_CHANGE';

//搜索事件
export const SEARCH_EVEVNT = 'SEARCH_EVEVNT';

// 登录后获取日历
export const GET_CALENDAR = 'GET_CALENDAR';

// 视图切换 loading
export const SET_LOADING = 'SET_LOADING';

// 设置年视图加载状态
export const SET_YEAR_SPINNING = 'SET_YEAR_SPINNING';

// 设置一年的事件
export const SET_YEAR_EVENTS = 'SET_YEAR_EVENTS';

// 搜索日历
export const SEARCH_CALENDAR = 'SEARCH_CALENDAR';

// 设置日历权限
export const SET_CALENDAR_RIGHT = 'SET_CALENDAR_RIGHT';

// 改变日历名称
export const CHANGE_CALENDAR_NAME = 'CHANGE_CALENDAR_NAME';

//日历分组表
export const calendarGroupId = '635361425012';
//我的日历表ID
export const myCalendarId = '623676968232';
//我的事件明细表ID
export const myCalendarEventsId = '623677239073';
//我的共享日历
export const myShareCalendarId = '631798050954';

/**
 * 获取日历数据
 * @param {string} start 开始日期时间
 * @param {string} end 结束日期时间
 * @param {string} key key
 */
export const getCalendar = (
  start,
  end,
  options = {
    key: '',
    loading: true,
    type: '',
    isSharedLink: false,
    sharedLink: '',
    isLogin: true,
  }
) => {
  return async (dispatch, getState) => {
    const {
      loading = true,
      key = '',
      type = '',
      isSharedLink = false,
      sharedLink = '',
      isLogin
    } = options;
    loading &&
      dispatch({
        type: SET_LOADING,
        data: { loading: true },
      });

    let res, params, err;

    params = {
      start,
      end,
    };

    if (isSharedLink) {
      params.sharedlink = sharedLink;
      if (isLogin) {
        params.calendar_resid = myCalendarId;
      }
    } else {
      params.calendar_resid = myCalendarId;
    }

    if (key) {
      params.cmswhere = `category_name like  '%${key}%'`;
    }

    if (isSharedLink) {
      if (isLogin) {
        [err, res] = await to(
          http().retrieveCalendarOccurence(params)
        );
      } else {
        [err, res] = await to(
          http().retrieveCalendarOccurenceBySharedLink(params)
        );
      }
    } else {
      [err, res] = await to(http().retrieveCalendarOccurence(params));
    }
    if (err) {
      return (
        loading &&
        dispatch({
          type: SET_LOADING,
          data: { loading: false },
        })
      );
    }

    const { CalendarGroupShareLink } = getState();

    const { calendarRightMap, rightCode, sharedId, groupId: sharedLinkGroupId } = CalendarGroupShareLink;

    res.data.forEach(item => {
      if (!Array.isArray(item.calendars)) {
        item.calendars = [];
      }
    });

    dispatch({
      type: GET_CALENDAR,
      data: {
        calendar: res.data,
        type,
        isSharedLink,
        calendarRightMap,
        rightCode,
        sharedId,
        sharedLinkGroupId
      },
    });

    dispatch({
      type: CALENDAR_GROUP_SHARE_LINK_INIT,
      data: {
        isSharedLink: false,
      },
    });
  };
};

/**
 * 获取年日历日历数据
 * @param {string} start 开始日期时间
 * @param {string} key key
 */
export const getYearCalendar = (start, loading = true, type, options) => {
  return async (dispatch, getState) => {
    const { isSharedLink = false, sharedLink = '', isLogin } = options || {};

    loading &&
      dispatch({
        type: SET_YEAR_SPINNING,
        data: { yearSpinning: getYearSpinning(true) },
      });

    const yearMonthRange = getYearMonthDateRange(
      moment(start).format('YYYY-MM-DD')
    );

    const len = yearMonthRange.length;

    for (let i = 0; i < len; i++) {
      const params = {
        start: yearMonthRange[i][0],
        end: yearMonthRange[i][1],
      };
      if (isSharedLink) {
        params.sharedlink = sharedLink;
        if (isLogin) {
          params.calendar_resid = myCalendarId;
        }
      } else {
        params.calendar_resid = myCalendarId;
      }

      let promise;
      if (isSharedLink) {
        promise = http().retrieveCalendarOccurenceBySharedLink(params);
      } else {
        promise = http().retrieveCalendarOccurence(params);
      }

      const { CalendarGroupShareLink } = getState();

      const { calendarRightMap, rightCode, sharedId } = CalendarGroupShareLink;

      promise
        .then((res) => {
          dispatch({
            type: SET_YEAR_EVENTS,
            data: {
              calendar: res.data,
              month: i,
              type,
              calendarRightMap,
              rightCode,
              sharedId,
              isSharedLink,
            },
          });
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
};

/**
 * 更新日历收藏的状态
 * @param {string} calendarData - 日历数据
 * @param {string} isCollected - 是否收藏
 */
export const updateCalendarCollectStatus = (calendarData, isCollected) => {
  return async (dispatch) => {
    dispatch({
      type: CALENDAR_COLLECT_CHANGE,
      data: {
        calendarData,
        isCollected,
      },
    });

    const [err] = await to(
      http().modifyRecords({
        resid: '607343850869',
        data: [{ ...calendarData, isCollected }],
      })
    );
    if (err) {
      dispatch({
        type: CALENDAR_COLLECT_CHANGE,
        data: {
          calendarData,
          isCollected: isCollected === 'Y' ? 'N' : 'Y',
        },
      });
      return message.error(err.message);
    }
  };
};
