import http from '@utils/api';
import { message } from 'antd';
import { plusReplace } from '@utils';

export const CALENDAR_GROUP_SHARE_LINK_INIT = 'CALENDAR_GROUP_SHARE_LINK_INIT';

/**
 * 获取分享链接信息
 */
export const getSharedLinkByEncode = (encodedShareId, callback) => {
  return async (dispatch) => {
    // 获取日历分组分享链接数据
    let res;
    let isValid = true;
    const encode = plusReplace(encodedShareId);
    try {
      res = await http().getSharedLinkByEncode({
        encode,
      });
    } catch (err) {
      if (err.message.indexOf('无效的链接') !== -1) {
        isValid = false;
      } else {
        message.error(err.message);
      }
    }
    if (!res || (res && !res.data)) {
      isValid = false;
    }

    // 所有子日历权限都为 0 时，返回的 accessToken 为 ''
    if (!res.data.AccessToken) {
      isValid = false;
    }

    // 是否开启
    if (res.data.isOpen !== 'Y') {
      isValid = false;
    }

    // 分享链接无效，跳转到无效页面
    if (!isValid) {
      window.location.href = '/invalid';
    }

    dispatch({
      type: CALENDAR_GROUP_SHARE_LINK_INIT,
      data: {
        resData: res.data,
        isValid,
        encodedShareId,
      },
    });
    callback && callback();
  };
};
