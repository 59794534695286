import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, PrivateOtherRoute, PrivateWXRoute } from './utils';
import {
  UserReserveCalendar2,
  Home,
  Welcome,
  EventDetails,
  PersonCenter,
  Square,
  EditorPC,
  ReserveServeType,
  ReserveOrderDetail,
  MyPersonCenter,
  MyOrder,
  GroupSet,
  CheckActivity,
  GroupShareSetting,
  GroupShareLinkEdit,
  WXLoginTest,
  WelcomeContainer
  // NewWelcome,
} from './pages/loadableCustom';
import { InValidPage } from './components/loadableCommon';
import DevTools from './components/DevTools';
import ReserveCalendar from './pages/ReserveCalendar';
import ShareCalendar from './pages/ShareCalendar';
import { getMedia } from './utils/media';
import { LocaleProvider } from 'antd';
import zhCN from 'antd/es/locale-provider/zh_CN';
import store from './redux/store';
import './App.less';

/**
 * 970px
 * 1310px
 */
const media = getMedia();

const App = () => {
  return (
    <LocaleProvider locale={zhCN}>
      <Provider store={store}>
        <Router>
          <Switch>
            <PrivateRoute path='/home/:id' component={Home} media={media} />
            <PrivateOtherRoute
              path='/home'
              exact
              component={Home}
              media={media}
            />
            <PrivateOtherRoute
              path='/:encodedShareId/home'
              exact
              component={Home}
              media={media}
            />
            {/* 默认地址 */}
            <PrivateRoute path='/' exact component={WelcomeContainer} />
            {/* <PrivateRoute path='/' exact component={Home} media={media} /> */}
            <PrivateWXRoute
              path='/login'
              exact
              component={Home}
              media={media}
            />
            <PrivateRoute
              path='/reserveCalendar/:calendarId/:mainNav/:subNav'
              component={ReserveCalendar}
              media={media}
              exact
            />
            <PrivateOtherRoute
              path='/reserveCalendar/:calendarId/:mainNav/:subNav/:encodedSharedId'
              component={ReserveCalendar}
              media={media}
              exact
            />

            <PrivateOtherRoute
              path='/userReserveCalendar:calendarId?'
              component={UserReserveCalendar2}
              media={media}
            />

            <PrivateOtherRoute
              path='/checkActivity:eventId?'
              component={CheckActivity}
              media={media}
            />

            <PrivateOtherRoute
              path='/serverType:groupId?'
              component={ReserveServeType}
              media={media}
            />

            <PrivateRoute
              path='/reserveOrder/:orderId'
              component={ReserveOrderDetail}
              media={media}
            />

            <PrivateRoute
              path='/myPersonCenter'
              component={MyPersonCenter}
              media={media}
            />

            <PrivateOtherRoute
              path='/myOrder'
              component={MyOrder}
              media={media}
            />

            <PrivateRoute
              path='/event-details'
              component={EventDetails}
              media={media}
            />

            <Route path='/square' component={Square} media={media} />

            <PrivateRoute
              path='/:groupId/groupSet'
              component={GroupSet}
              exact
              media={media}
            />
            <PrivateRoute
              path='/:groupId/groupSet/order'
              component={GroupSet}
              exact
              media={media}
            />

            {/* 分享链接列表页 */}
            <PrivateRoute
              path='/:encodedGroupId/groupShareSetting'
              component={GroupShareSetting}
              media={media}
              exact
            />
            <PrivateOtherRoute
              path='/:encodedShareId/:encodedGroupId/groupShareSetting'
              component={GroupShareSetting}
              media={media}
              exact
            />
            {/* 分享链接编辑页 */}
            <PrivateRoute
              path='/:encodedGroupId/groupShareSetting/:encodedShareId'
              component={GroupShareLinkEdit}
              media={media}
              exact
            />
            <PrivateOtherRoute
              path='/:linkEncodedShareId/:encodedGroupId/groupShareSetting/:encodedShareId'
              component={GroupShareLinkEdit}
              media={media}
            />

            <PrivateRoute
              path='/:calendarId/editorPC'
              component={EditorPC}
              media={media}
              exact
            />
            {/* 分享链接访问日历编辑页面 */}
            <PrivateOtherRoute
              path='/:calendarId/editorPC/:encodedSharedId'
              component={EditorPC}
              media={media}
            />

            <PrivateRoute
              path='/shareCalendar/:calendarId/:mainNav/:subNav'
              component={ShareCalendar}
              media={media}
              exact
            />
            <PrivateOtherRoute
              path='/shareCalendar/:calendarId/:mainNav/:subNav/:encodedSharedId'
              component={ShareCalendar}
              media={media}
            />

            <PrivateRoute
              path='/person-center/:mainNav/:subNav'
              component={PersonCenter}
              media={media}
            />
            {/* <Route path='/welcome' component={NewWelcome} media={media} /> */}

            <PrivateOtherRoute
              path='/invalid'
              component={InValidPage}
              type='linkInvalid'
              media={media}
            />

            <Route path='/wxlogin-test' component={WXLoginTest} />

            <Route path='*' component={InValidPage} media={media} />
          </Switch>
        </Router>
        <DevTools />
      </Provider>
    </LocaleProvider>
  );
};

export default App;
